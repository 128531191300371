<script setup>
    // Seite zum Verschieben vergangener Wiedervorlagen
    import { ref, computed, onMounted } from 'vue'
    import router from '../../router/routes';

    import Reminder from '@/components/Reminder.vue'

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore()

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore()


    const reschedule = ref([])
    onMounted(() => {
        const businessPartnerList = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`)
        reschedule.value= businessPartnerList.map((businessPartner) => ({
            id: businessPartner.salesPitch.id,
            businessPartner: {
                id: businessPartner.id,
                name: businessPartner.name,
                notes: businessPartner.notes,
                address: {
                    city: businessPartner.addressList[0].city.name,
                    houseNumber: businessPartner.addressList[0].houseNumber,
                    name: businessPartner.addressList[0].name,
                },
                salesPitch: {
                    count: businessPartner.salesPitch.count,
                    comment: businessPartner.salesPitch.comment,
                },
            },
            rescheduleDate: null,
            rescheduleTime: null,
            selected: false,
            unscheduled: true,
            isValid:false,
        }))
    })

const unscheduled = computed(() => (reschedule.value) ? reschedule.value.filter(item => item.unscheduled).length : 0)

const handleReschedule = async (id) => {
    const entry = reschedule.value.find((item) => item.businessPartner.id == id)

    const rescheduleSuccess = await businessPartnersStore.rescheduleSalesPitch(entry)
    // console.log('rescheduling:', entry, 'success', rescheduleSuccess)
    if (rescheduleSuccess) entry.unscheduled = false
    if (reschedule.value.filter(item => item.unscheduled).length === 0) router.push({ name: 'Dashboard', hash: '#potential-bk' })
}

const handleSetDate = async (id) => {
    const entry = reschedule.value.find((item) => item.businessPartner.id == id)
    // console.log('set date:', entry)
    entry.isValid = (entry.rescheduleDate) ? true : false
}

</script>

<template>
    <div class="reminder-wrapper singlepage-wrapper max-width">
        <main id="container-main">
            <div>
                <div class="widget-wrapper potential-bk followUp">
                    <router-link to="/dashboard#potential-bk">
                        <div class="headline-wrapper">
                            <h2>To-Do <span>{{ unscheduled }}</span></h2>
                        </div>
                    </router-link>
                     <div class="container-wrapper">
                        <div class="businessPartnerList" ref="businessPartnerList">
                            <div class="container-wrapper scroll-y">
                                <div :class="['customer']" v-for="(entry, index) in reschedule.filter((item) => item.unscheduled) || []" :key="entry.businessPartner.id">
                                    <span v-if="index < 9" class="businessPartnerIndex">0{{ index + 1 }}</span>
                                    <span v-else class="businessPartnerIndex">{{ index + 1 }}</span>
                                    <div class="informations-wrapper">
                                        <div class="accordion-menu notes-details">
                                            <ul class="main-list">
                                                <li class="main-list-item">
                                                    <h3>
                                                        {{ entry.businessPartner.name }}
                                                        <i class="arrow"></i>
                                                    </h3>
                                                    <div class="accordion-content-wrapper">
                                                        <div class="accordion-content">
                                                            <div class="informations-wrapper">
                                                                <div class="input-wrapper feedback list">
                                                                    <div :class="['potential-wrapper container']">
                                                                        <div class="headline">
                                                                            <h4 class="status-heading product left">
                                                                                <textarea readonly v-if="entry.businessPartner.salesPitch.comment === null">Kein Kommentar vorhanden.</textarea>
                                                                                <textarea readonly v-else>{{ entry.businessPartner.salesPitch.comment }}</textarea>
                                                                            </h4>
                                                                            <span class="index">#{{ entry.businessPartner.salesPitch.count }}</span>
                                                                        </div>
                                                                        <div class="inputs">
                                                                            <input class="form-child" @change="handleSetDate(entry.businessPartner.id)" type="date" v-model="entry.rescheduleDate">
                                                                            <input class="form-child" @change="handleSetDate(entry.businessPartner.id)" type="time" list="appt-time" v-model="entry.rescheduleTime"/>
                                                                        </div>
                                                                        <datalist>
                                                                            <option value="06:30"></option>
                                                                            <option value="07:00"></option>
                                                                        </datalist>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button @click="handleReschedule(entry.businessPartner.id)" :disabled="!entry.isValid">Speichern</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <reminder></reminder>
    </div>
</template>

<style scoped>
/* Hier könnten Sie Ihre CSS-Stile hinzufügen */
</style>