<script setup>
    import { ref, computed } from 'vue'
    import router from '../../router/routes';

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();
    
    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();

    const businessPartnerSearch = ref('');

    const exceededToDos = computed(() => {
        if (userStore.currentSelection.scope!=="salesAgent") return null;
        
        // if (userStore.surname === 'vom Hofe') return null; // debug only
        const salesPitchFollowUps = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`);
        const salesPitchFeedbacks = businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`);
        return {
            both: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp && (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback,
            followUps: (salesPitchFollowUps || []).length > userStore.maxToDos.followUp,
            feedbacks: (salesPitchFeedbacks || []).length > userStore.maxToDos.feedback
        }
    });

    const isLoading = computed(() => {
        return (userStore.currentSelection.businessPartnerList.isLoading || userStore.currentSelection.businessPartner.isLoading);
    });


    // Define a function to handle form submissions
    const handleBusinessPartnerSearch = () => {
        // console.log('searching for:', businessPartnerSearch.value, userStore.currentSelection.businessPartnerList.isLoading);
        router.push({ name: 'BusinessPartner', query: { source: 'search', searchMode: 'all', search: businessPartnerSearch.value}});
    };
    const handleSelectList = (idList) => {
        // console.log('routing to list:', idList);
        // set the page to 1 to avoid pagination issues
        userStore.currentSelection.businessPartnerList.page = 1;
        userStore.currentSelection.businessPartnerList.pageSize = 24;
        userStore.currentSelection.businessPartnerList.filter.idBusinessPartnerList = idList;
        router.push({ name: 'BusinessPartnerList', query: { source: 'filter', filter: `idBusinessPartnerStatus:1|idBusinessPartnerAboStatus:-1|idBusinessPartnerList:${idList}|idBusinessPartnerProduct:-1|idBusinessPartnerPotential:-1|idBusinessPartnerCityZip:-1|nameBusinessPartnerSector:|idBusinessPartnerOrder:-1` }});
    };

    const dropdownVisible = ref(false);

    const toggleDropdown = () => {
        dropdownVisible.value = !dropdownVisible.value;
    };

</script>

<template>
    <div id="potential-bk" :class="['widget-wrapper potential-bk', {'noValue' : (userStore.isManagement && userStore.currentSelection.salesAgent.id === -1), 'exceeded' : (exceededToDos && (exceededToDos.feedbacks===true || exceededToDos.followUps===true))}]">
        <div>
            <router-link to="/businessPartnerList?source=filter&filter=idBusinessPartnerStatus:1">
                <div class="headline-wrapper">
                    <h2>Potential & BK</h2>
                    {{ businessPartnersStore.namedFilter[0] }}
                </div>
            </router-link>
        </div>
        <div class="container-wrapper">
            <div v-if="isLoading === true" class="container main-content">
                <div class="loader" id="loader"></div>
            </div>
            <div class="container main-content">
                <div class="warning-wrapper">
                    <div>
                        <router-link to="/salesPitchFollowUp" :class="['warning-follow-up btn', {'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length > 0}]" >
                            Wiedervorlagen To-Do
                            <span class="number-label">
                                {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:Wiedervorlagen`).length}}
                                {{ (!userStore.isSalesTeamManager && !userStore.isManagement) ? ` / ${userStore.maxToDos.followUp}` : ''}}
                            </span>
                        </router-link>
                    </div>
                    <div>
                        <router-link to="/salesPitchFeedback" :class="['warning-appointments btn', {'negative' : businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length > 0}]">
                            Termin-Feedback
                            <span class="number-label">
                                {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#ToDo:VorOrtTermine`).length}}
                                {{ (!userStore.isSalesTeamManager && !userStore.isManagement) ? ` / ${userStore.maxToDos.feedback}` : ''}}
                            </span>
                        </router-link>
                    </div>
                </div>
                <template v-if="!exceededToDos || (exceededToDos.feedbacks===false && exceededToDos.followUps===false)">
                    <div class="custom-dropdown">
                        <!-- <button @click="handleSugarLists" class="dropdown-toggle btn" id="dropdown-toggle">
                            Neues Kundenmaterial <span class="highlight"> {{ 2}}</span> -->
                            <div class="arrowWrapper">
                                <button @click="toggleDropdown" class="dropdown-toggle" id="dropdown-toggle">
                                    Unb. Leads / Aktionen
                                    <span v-if="businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`).length > 0" class="number-label">
                                        {{ businessPartnersStore.businessPartnerListBySignature(`${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}#LeadList`).length}}
                                    </span>
                                </button>
                            </div>
                        <ul :style="{ display: dropdownVisible ? 'block' : 'none' }" class="dropdown-menu" id="dropdown-menu">
                            <li v-for="list of businessPartnersStore.businessPartnerLeadLists" @click="handleSelectList(`list:${list.id}`)"  :key="list.id" class="dropdown-item" data-value="option1">
                                <div>{{ list.name }} <span class="number-label">{{ list.businessPartners.length }}</span></div>
                            </li>
                        </ul>
                    </div>
                    <form :class="{'noResults': userStore.currentSelection.businessPartnerList.search.error}" @submit.prevent="handleBusinessPartnerSearch">
                        <!-- {{ isLoading }} -->
                        <!-- <div v-if="isLoading" class="loader" id="loader"></div> -->
                        <span class="number-label">Ups! Nichts Passendes gefunden.</span>
                        <input 
                            v-model="businessPartnerSearch" type="text" id="search" 
                            :class="['btn']" 
                            placeholder="GP: Name / Nr. / Tel. ...">
                    </form>
                </template>
                <div v-else class="warningText">
                    <h2>Warnung!</h2>
                    <p v-if="exceededToDos.both">Die maximale Anzahl unbearbeiteter Termine von {{ userStore.maxToDos.feedback }} und Wiedervorlagen von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite Feedback und Wiedervorlagen, um fortzufahren.</p>
                    <p v-else-if="exceededToDos.feedbacks">Die maximale Anzahl unbearbeiteter Termine von {{ userStore.maxToDos.feedback }} wurde erreicht. Bitte bearbeite Feedback, um fortzufahren.</p>
                    <p v-else>Die maximale Anzahl unbearbeiteter Wiedervorlagen von {{ userStore.maxToDos.followUp }} wurde erreicht. Bitte bearbeite ältere Wiedervorlagen, um fortzufahren.</p>
                </div>
            </div>
        </div>
        <div class='wave-wrapper'>
            <div class='wave -one'></div>
            <div class='wave -two'></div>
            <div class='wave -three'></div>
        </div>
    </div>
</template>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>


<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->