<script setup>
    import { ref } from 'vue'

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { useRatingsStore } from '../../stores/ratings';
    const ratingsStore = useRatingsStore();

    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
    };

    function formattedSalesAgentName(name) {
    return name
        .toLowerCase() // Konvertiere in Kleinbuchstaben
        .replace(/ä/g, 'ae') // Ersetze ä durch ae
        .replace(/ö/g, 'oe') // Ersetze ö durch oe
        .replace(/ü/g, 'ue') // Ersetze ü durch ue
        .replace(/ß/g, 'ss') // Ersetze ß durch ss
        .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
        + '.webp'; // Füge die Dateiendung hinzu
    };

</script>

<template>
<div id="reviews" class="widget-wrapper reviews noValue">
    <div>
        <router-link to="/reviews">
            <div class="headline-wrapper">
                <h2>Bewertungen</h2>
            </div>
        </router-link>
    </div>
    <div class="container-wrapper">
        <div v-if="ratingsStore.ratings.length === 0" class="container">
            <div class="warning">Keine Daten verfügbar!</div>
        </div>
        <div v-if="userStore.currentSelection.ratings.isLoading === true" class="container main-content">
            <div class="loader" id="loader"></div>
        </div>
        <div v-else v-for="rating of ratingsStore.rating[userStore.currentSelection.ratings.display]?.ratings" :key="rating.businessPartner.GPNo" class="container main-content">
            <div class="rating-row">
            <!-- <div class="rating-row current-week"> -->
                <div class="content-wrapper">
                    <div class="bewertung-whole-wrapper">
                        <div class="salesManager-badges">
                            <div class="badges-wrapper">
                                <span class="date">{{formatDate(rating.rating.captured)}}</span>
                                <span class="gp">GP: &nbsp; {{rating.businessPartner.GPNo}}</span>
                            </div>
                            <div v-if="userStore.currentSelection.scope !== 'salesAgent'" class="salesManager">
                                <div class="sm-wrapper nav">
                                    <div :class="['rank rating-4', userStore.status?.current?.toLowerCase().replaceAll(' ','-')]">
                                        <p class="rating-value">{{ ratingsStore.rating?.week?.bySalesAgent.find(salesAgent => salesAgent.id === rating.salesAgent.id)?.average.ratings }}</p>
                                        <img :src="'avatars/' + formattedSalesAgentName(rating.salesAgent.firstname + ' ' + rating.salesAgent.surname)" alt="SM Profilbild">
                                    </div>
                                </div>
                                <div class="informations-wrapper">
                                    <div class="txt-wrapper">
                                        <p><b>{{rating.salesAgent.firstname + ' ' + rating.salesAgent.surname}}</b></p>
                                        <i>{{rating.salesAgent.salesAgentNo}}</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="businessPartner">
                            <div class="txt-wrapper">
                                <p><b>{{rating.businessPartner.name}}</b></p>
                                <!-- <i>{{rating.businessPartner.GPNo}}</i> -->
                            </div>
                        </div>
                        <div class="star-wrapper">
                            <div class="star-wrapper-top">
                                <div class="value-wrapper"><span>Stattgefunden?</span><p class="question positive">{{rating.rating.checkbox_01}}</p></div>
                                <div class="value-wrapper"><span>Vertrauenswürdig?</span><p :class="['_' + rating.rating.average_01_01]">{{rating.rating.average_01_01}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                                <div class="value-wrapper"><span>Kundenorientiert?</span><p :class="['_' + rating.rating.average_02_02]">{{rating.rating.average_02_02}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                            </div>
                            <div class="star-wrapper-bottom">
                                <div class="value-wrapper"><span>Überzeugt?</span><p class="question negative">{{rating.rating.checkbox_02}}</p></div>
                                <div class="value-wrapper"><span>Zufriedenstellend?</span><p :class="['_' + rating.rating.average_03_03]">{{rating.rating.average_03_03}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                                <div class="value-wrapper"><span>Weiterempfehlung?</span><p :class="['_' + rating.rating.average_04_04]">{{rating.rating.average_04_04}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <div class='wave-wrapper'>
        <div class='wave -one'></div>
        <div class='wave -two'></div>
        <div class='wave -three'></div>
    </div>
    <!-- <div class="toggle-wrapper"> 
        <div class="toggle-option">
            <span class="toggle-label">Woche</span>
            <label class="switch">
                <input type="radio" name="activity-toggle" checked>
                <span class="custom-radio"></span>
            </label>
        </div>
        <div class="toggle-option">
            <span class="toggle-label">Monat</span>
            <label class="switch">
                <input type="radio" name="activity-toggle">
                <span class="custom-radio"></span>
            </label>
        </div>
    </div> -->
</div>
</template>

<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->