<script setup>
    
    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { useRatingsStore } from '@/stores/ratings'
    const ratingsStore = useRatingsStore();

    function formatEuroValues(value) {
        return (value && !isNaN(value)) ? value.toLocaleString('de-DE'): 0;
    }

    async function handleDisplayStatus(display) {
      await userStore.selectDisplayStatus('incentives', display);
    }

    function formattedSalesAgentName(name) {
    return name
        .toLowerCase() // Konvertiere in Kleinbuchstaben
        .replace(/ä/g, 'ae') // Ersetze ä durch ae
        .replace(/ö/g, 'oe') // Ersetze ö durch oe
        .replace(/ü/g, 'ue') // Ersetze ü durch ue
        .replace(/ß/g, 'ss') // Ersetze ß durch ss
        .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
        + '.webp'; // Füge die Dateiendung hinzu
    };

    const handleImageError = (event) => {
        event.target.src = 'avatars/dummy-avatar.webp';
    };

</script>

<template>
  <div id="champions" class="widget-wrapper champions">
        <div>
            <router-link to="/incentives#anchor">
                <div class="headline-wrapper">
                    <h2>Champions</h2>
                    <p>{{incentivesStore.incentive.salesAgents?.name}} ({{incentivesStore.incentive.salesAgents?.dateStart?.split(', ')[0]}} - {{incentivesStore.incentive.salesAgents?.dateEnd?.split(', ')[0]}})</p>
                </div>
            </router-link>
        </div>
        <div class="container-wrapper">
            <div v-if="userStore.currentSelection.incentives.isLoading" class="container main-content toggle-me">
                <div class="loader" id="loader"></div>
            </div>
            <div v-else class="container main-content toggle-me">
                <template v-if="incentivesStore.incentiveSales?.salesAgents?.length > 0">  
                    <div class="winners-podium">
                        <div :class="['winner-wrapper', {'highlighted' : entry.highlightedPosition === true}]" v-for="(entry, index) in incentivesStore.topThree[userStore.currentSelection.incentives.display]" :key="entry.id">
                            <div :class="['winner', index === 0 ? '-second' : index === 1 ? '-first' : '-third']">
                                <img @error="handleImageError" v-if="userStore.currentSelection.incentives.display==='salesAgents'" :src="'avatars/' + formattedSalesAgentName(entry.salesAgentName)" alt="SM Profilbild">
                                <img @error="handleImageError" v-if="userStore.currentSelection.incentives.display==='salesTeams'" :src="'avatars/' + formattedSalesAgentName(entry.salesTeamName)" alt="SM Profilbild">
                                <!-- <img src="@/assets/imgs/champions/dummy-avatar.png" alt="SM Profilbild"> -->
                            </div>
                            <div class="txt-wrapper">
                                <span v-if="userStore.currentSelection.incentives.display==='salesAgents'">{{ entry.salesAgentName }}</span>
                                <span v-if="userStore.currentSelection.incentives.display==='salesTeams'">{{ entry.salesTeamName }}</span>
                                <span v-if="incentivesStore.incentive.salesAgents.focus==='salesEuroNew'">{{ formatEuroValues(entry.salesEuroNew) }}€</span>
                                <span v-if="incentivesStore.incentive.salesAgents.focus==='salesEuro'">{{ formatEuroValues(entry.salesEuro) }}€</span>
                                <span v-if="entry.rankLastImport !== null" class="rank">{{ entry.rankLastImport }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="ranklist-wrapper">
                        <div class="ranklist">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Rang</th>
                                        <th v-if="userStore.currentSelection.incentives.display==='salesAgents'">Sales Manager</th>
                                        <th v-if="userStore.currentSelection.incentives.display==='salesTeams'">Areas</th>
                                        <template v-if="incentivesStore.incentive.salesAgents.focus==='salesEuroNew'">
                                            <th>Neu-Kd.</th>
                                            <th>Umsatz</th>
                                        </template>
                                        <template v-if="incentivesStore.incentive.salesAgents.focus==='salesEuro'">
                                            <th>Umsatz</th>
                                            <th>Neu-Kd.</th>
                                        </template>
                                        <th v-if="userStore.currentSelection.incentives.display==='salesAgents'">Bonus<span class="asterisk">&#42;&sup1;</span></th>
                                        <th v-if="userStore.currentSelection.incentives.display==='salesTeams'">Teamkasse<span class="asterisk">&#42;&sup2;</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(entry, index) in incentivesStore.preview" :key="index"
                                        :class="{ 'logged-in': entry.highlightedPosition}">
                                        <td class="position" :class="{'rank-out': entry.rankChangeDirection === 'out', 'rank-new': entry.rankChangeDirection === 'new', 'rank-up': entry.rankChangeCount > 0, 'rank-hold': entry.rankChangeCount === '0', 'rank-down': entry.rankChangeCount < 0}">
                                            <template v-if="entry.rankChangeDirection === 'out'">ST</template>
                                            <template v-else-if="entry.rankLastImport!==null">{{ entry.rankLastImport.toString().padStart(2,'0') }}</template>
                                            <template v-else-if="entry.rankLastImport===null">--</template>
                                            <span class="change-count" v-if="entry.rankChangeDirection === 'new'">neu</span>
                                            <span class="change-count" v-if="entry.rankChangeDirection === 'out'">storno</span>
                                            <span class="change-count" v-if="entry.rankChangeCount !== '0'">{{ entry.rankChangeCount }}</span>
                                            <span class="highlightBorder" v-if="entry.highlightedPosition === true"></span>
                                        </td>
                                        <td class="sm-wrapper">
                                        <div :class="['rank', (entry.salesAgentStatusCurrent) ? entry.salesAgentStatusCurrent.toLowerCase().replaceAll(' ','-') : '', 'rating-' + entry.rating]">
                                            <p class="rating-value">{{ ratingsStore.rating?.week?.bySalesAgent?.find((salesAgent) => salesAgent.idUser === entry.idUser )?.average.ratings }}</p>
                                            <img @error="handleImageError" v-if="userStore.currentSelection.incentives.display==='salesAgents'" :src="'avatars/' + formattedSalesAgentName(entry.salesAgentName)" alt="SM Profilbild">
                                            <img @error="handleImageError" v-if="userStore.currentSelection.incentives.display==='salesTeams'" :src="'avatars/' + formattedSalesAgentName(entry.salesTeamName)" alt="SM Profilbild">
                                        </div>
                                        <div class="marquee" v-if="userStore.currentSelection.incentives.display==='salesAgents'">
                                            <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p>
                                            <!-- <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p> -->
                                        </div>
                                        <div class="marquee" v-if="userStore.currentSelection.incentives.display==='salesTeams'">
                                            <p>{{ entry.salesTeamName }}</p>
                                            <!-- <p>{{ entry.salesTeamName }}</p> -->
                                        </div>
                                        </td>
                                        <template v-if="incentivesStore.incentive.salesAgents.focus==='salesEuroNew'">
                                            <td>{{ formatEuroValues(entry.salesEuroNew) }}€</td>
                                            <td>{{ formatEuroValues(entry.salesEuro) }}€</td>
                                        </template>
                                        <template v-if="incentivesStore.incentive.salesAgents.focus==='salesEuro'">
                                            <td>{{ formatEuroValues(entry.salesEuro) }}€</td>
                                            <td>{{ formatEuroValues(entry.salesEuroNew) }}€</td>
                                        </template>
                                        <td :class="{ 'achieved' : entry.salesBonusEntitled === true  && entry.rankLastImport < incentivesStore.incentive.salesAgents.maxChampions, 'not-achieved' : entry.salesBonusEntitled === false  && entry.rankLastImport <= incentivesStore.incentive.salesAgents.maxChampions}">
                                                {{ formatEuroValues(entry.salesBonus) }}€
                                        </td>
                                    </tr>
                                    <tr v-if="userStore.currentSelection.incentives.display==='salesAgents' && index === incentivesStore.incentive.salesAgents.maxChampions - 1 && index < incentivesStore.incentiveSales.salesAgents.length - 1"  
                                        class="separator">
                                        <td v-for="i in [1,2,3,4,5]" :key="i">
                                            <span></span>
                                        </td>
                                    </tr>
                                    <tr v-if="userStore.currentSelection.incentives.display==='salesTeams' && index === incentivesStore.incentive.salesTeams.maxChampions - 1 && index < incentivesStore.incentiveSales.salesTeams.length - 1"  
                                        class="separator">
                                        <td v-for="i in [1,2,3,4,5]" :key="i">
                                            <span></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
                <div v-else class="warning">Keine Daten verfügbar!</div>
            </div>
        </div>
        <div class='wave-wrapper'>
            <div class='wave -one'></div>
            <div class='wave -two'></div>
            <div class='wave -three'></div>
        </div>
        <div class="toggle-wrapper">
            <div class="toggle-option" @click="handleDisplayStatus('salesAgents')">
                <span class="toggle-label">SalesManager</span>
                <label class="switch">
                    <input type="radio" name="champions-toggle" value="salesAgents" :checked="userStore.currentSelection.incentives.display==='salesAgents'">
                    <span class="custom-radio"></span>
                </label>
            </div>
            <div class="toggle-option" @click="handleDisplayStatus('salesTeams')" >
                <span class="toggle-label">Areas</span>
                <label class="switch">
                    <input type="radio" name="champions-toggle" value="salesTeams" :checked="userStore.currentSelection.incentives.display==='salesTeams'">
                    <span class="custom-radio"></span>
                </label>
            </div>
        </div>
  </div>
</template>

<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->