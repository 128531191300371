<script setup>
    import { useRouter } from 'vue-router'
    import { ref } from 'vue'
    import AOS from 'aos'

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();
    userStore.$reset();

    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();
    incentivesStore.$reset();

    import { useBusinessPartnersStore } from '@/stores/businessPartners'
    const businessPartnersStore = useBusinessPartnersStore();
    businessPartnersStore.$reset();

    const router = useRouter();

    const email = ref('')
    const password = ref('')

    async function handleLogin() {
        userStore.isLoading = true;
        await userStore.login(email.value, password.value)
        userStore.isLoading = false;
        if (userStore.loginStatus === 'error' || userStore.roleList?.length === 0) {
            // console.log('login failed')
        } else if (userStore.loginStatus === 'success') {
            // console.log('login success')
            // const token = userStore.token; // Angenommen, das Token wird hier gespeichert
            if (rememberMe.value) {
                // localStorage.setItem('authToken', token)
                localStorage.setItem('rememberMe', true)
                localStorage.setItem('email', email.value)
                localStorage.setItem('password', password.value)
            } else {
                if(!localStorage.getItem('rememberMe')) {
                    // console.log('no remember me');
                    // sessionStorage.setItem('authToken', token)
                    localStorage.removeItem('rememberMe')
                    localStorage.removeItem('email')
                    localStorage.removeItem('password')
                }
            }
            router.push('/dashboard')
        }
    }

    function autoLogin() {
        const rememberMe = localStorage.getItem('rememberMe')
        const savedEmail = localStorage.getItem('email')
        const savedPassword = localStorage.getItem('password')
        // const token = localStorage.getItem('authToken')

        if (rememberMe && savedEmail && savedPassword) {
            email.value = savedEmail
            password.value = savedPassword
            handleLogin()
        }
    }

    // Rufen Sie autoLogin auf, wenn die Komponente geladen wird
    autoLogin()

    const showPassword = ref(false);
    function togglePasswordVisibility() {
        showPassword.value = !showPassword.value;
    }

    const rememberMe = ref(false);

    const openLogin = ref(false);
    function handleOpenLogin() {
        openLogin.value = !openLogin.value;
        setTimeout(() => {
            AOS.refresh();
        }, 300);
    }
</script>

<template>
    <div class="login-wrapper max-width">
        <div class="login-header">
            <div class="header-content">
                <img class="logo" src="@/assets/imgs/logos/sellwerk/Sellwerk_logo_Mittelstand_rgb-white-sternprinzip.svg">
                <p  data-aos="fade-up"
                    data-aos-easing="ease-out-sine"
                    data-aos-delay="250">Willkommen bei deinem</p>
                <h1 data-aos="fade-in"
                    data-aos-easing="ease-out-sine"
                    data-aos-delay="250"><br><b>Sales Manager <br> Assistant!</b></h1>
                <p  data-aos="fade-down"
                    data-aos-easing="ease-out-sine"
                    data-aos-delay="250">Logge Dich ein, um deine Kampagnen effizient zu verwalten und alle relevanten Informationen auf einen Blick zu haben.</p>
                <!-- <button class="btn"><a class="buttonlink" href="#form">Zum Login</a></button> -->
                <div class="buttons">
                    <button @click="handleOpenLogin()" class="revert">Einloggen</button>
                    <button disabled class="revert">Änderungsprotokoll</button>
                </div>
            </div>
            <!-- <div class="clip-path-wrapper">
                <div class="clip-path"></div>
                <div class="clip-path"></div>
                <div class="clip-path"></div>
            </div> -->
            <picture>
                <source media="(min-width: 1024px)" srcset="@/assets/imgs/login/login-header-bg-01-desktop.svg">
                <source srcset="@/assets/imgs/login/login-header-bg-01.svg">
                <img class="header_bg_image" src="@/assets/imgs/login/login-header-bg-01.svg" alt="">
            </picture>
            <picture>
                <source media="(min-width: 1024px)" srcset="@/assets/imgs/login/login-header-bg-02-desktop.svg">
                <source srcset="@/assets/imgs/login/login-header-bg-02.svg">
                <img class="header_bg_image" src="@/assets/imgs/login/login-header-bg-02.svg" alt="">
            </picture>
            <!-- <div class='box'>
                <div class='wave -one'> </div>
                <div class='wave -two'></div>
            </div> -->
        </div>
        <div :class="['form-wrapper', {'open' : openLogin === true}]"> 
            <div @click="handleOpenLogin()" class="close-login" v-if="openLogin === true">
                <img src="@/assets/imgs/icons/xmark-solid.svg" alt="Close Icon">
            </div>
            <div class="wrapper-left">
                <div class="screen-content">
                    <h2>Willkommen zurück!</h2>
                    <hr>
                    <img src="@/assets/imgs/logos/sellwerk/Sellwerk_logo_Mittelstand_rgb-white-sternprinzip.svg">
                    <p>Logge Dich ein, um deine Kampagnen effizient zu verwalten und alle relevanten Informationen auf einen Blick zu haben.</p>
                    <div class='box'>
                        <div class='wave -one'> </div>
                        <div class='wave -two'></div>
                    </div>
                </div>

            </div>
            <div v-if="userStore.isLoading === true" class="loader-overlay">
                <div class="loader" id="loader"></div>
            </div>
            <div id="form" :class="['wrapper-right', {'error' : userStore.loginStatus === 'error'}]">
                <div class="screen-content">
                    <form class="login" @submit.prevent="submit">
                        <div class="form-header">
                            <div    data-aos="fade-left"
                                    data-aos-easing="ease-out-sine"
                                    data-aos-delay="1250"
                                    data-aos-anchor="#form"
                            class="headline">
                                <sup>1.0 alpha</sup>
                            </div>
                            <!-- <p class="subline">Bitte logge dich ein.</p> -->
                        </div>
                        <p v-if="userStore.loginStatus === 'error'" class="error-msg">Es ist ein Fehler aufgetreten. Bitte überprüfe deine Eingaben und versuche es erneut.</p>
                        <div class="headline-txt">
                            <p  data-aos="fade-right"
                                data-aos-easing="ease-out-sine"
                                data-aos-delay="100"
                                data-aos-anchor="#form"
                            class="headline">Melde dich im Konto an.</p>
                            <p  data-aos="fade-right"
                                data-aos-easing="ease-out-sine"
                                data-aos-delay="200"
                                data-aos-anchor="#form"
                            class="subline">Nutze alle wichtigen Daten auf einen Blick. <br> Analysiere deine Umsätze, Verkäufe und Zahlen.</p>
                        </div>
                        <div class="input-wrapper">
                            <div    data-aos="fade-right"
                                    data-aos-easing="ease-out-sine"
                                    data-aos-delay="250"
                                    data-aos-anchor="#form"
                            class="login-field">
                                <img    data-aos="zoom-in"
                                        data-aos-easing="ease-out-sine"
                                        data-aos-delay="300"
                                        data-aos-anchor="#form"
                                src="@/assets/imgs/icons/user-solid.svg" alt="Person Icon">
                                <input v-model="email" type="text" class="login-input" placeholder="E-Mail">
                            </div>
                            <div    data-aos="fade-right"
                                    data-aos-easing="ease-out-sine"
                                    data-aos-delay="300"
                                    data-aos-anchor="#form"
                            class="login-field">
                                <img    data-aos="zoom-in"
                                        data-aos-easing="ease-out-sine"
                                        data-aos-delay="350"
                                        data-aos-anchor="#form"
                                src="@/assets/imgs/icons/lock-solid.svg" alt="Lock Icon">
                                <input v-model="password" :type="showPassword ? 'text' : 'password'" class="login-input" placeholder="Passwort">
                                <button class="togglePasswordVisibility" @click="togglePasswordVisibility">
                                    <span v-if="showPassword"><img src="@/assets/imgs/icons/eye-slash-solid.svg"></span>
                                    <span v-else><img src="@/assets/imgs/icons/eye-solid.svg"></span>
                                </button>
                            </div>
                            <div class="actions">
                                <div    data-aos="fade-right"
                                        data-aos-easing="ease-out-sine"
                                        data-aos-delay="350"
                                        data-aos-anchor="#form"
                                class="remember-me-wrapper">
                                    <input v-model="rememberMe" type="checkbox" id="remember-me" class="login-checkbox"> 
                                    <label for="remember-me">Angemeldet bleiben</label>
                                </div>
                                <p      data-aos="fade-right"
                                        data-aos-easing="ease-out-sine"
                                        data-aos-delay="400"
                                        data-aos-anchor="#form"
                                class="forgot-password"><a href="#">Passwort vergessen?</a></p>
                            </div>
                        </div>
                        <button     data-aos="fade-up"
                                    data-aos-easing="ease-out-sine"
                                    data-aos-delay="400"
                                    data-aos-anchor="#form"
                        @click="handleLogin()" class="login-submit">
                            <span class="button-text">Einloggen</span>
                        </button>				
                    </form>
                </div>
                <div class="screen-background">
                    <span   data-aos="rotate"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="60"
                            data-aos-anchor="#form"
                            class="screen-background-shape screen-background-shape4"></span>
                    <span   data-aos="rotate"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="80"
                            data-aos-anchor="#form"
                            class="screen-background-shape screen-background-shape3"></span>		
                    <span   data-aos="rotate"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="70"
                            data-aos-anchor="#form"
                            class="screen-background-shape screen-background-shape2"></span>
                    <span   data-aos="rotate"
                            data-aos-easing="ease-out-sine"
                            data-aos-delay="50"
                            data-aos-anchor="#form"
                            class="screen-background-shape screen-background-shape1"></span>
                </div>		
            </div>
        </div>
    </div>
</template>