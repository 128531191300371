import { defineStore } from "pinia";

import { useUserStore } from "./user";

import { setKey as setBrowserStorageKey } from "./helpers/localStorage.mjs";
import { fetchFromBackend } from "./helpers/backend.mjs";

export const useRatingsStore = defineStore("ratings", {
  state: () => {
    return {
      ratings: [],
    }
  },
  getters: {
    // return the full rating data, the signature allows for finding the activity by display (i.e. week or month) and campaign.id
    rating: (state) => {
      if (!state.ratings) return false;
      const userStore = useUserStore();
      let rating = null;
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          rating = state.ratings.find((activity) => activity.signature === `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'salesTeam':
          rating = state.ratings.find((activity) => activity.signature === `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'campaign':
          rating = state.ratings.find((activity) => activity.signature === `campaign:${userStore.currentSelection.campaign.id}`);
          break;
      }
      if (!rating?.data?.week?.ratings) return [];
      rating.data.week?.ratings.sort((b, a) => new Date(a.rating.captured) - new Date(b.rating.captured));
      return rating.data;
    },
  },
  actions: {
    /** see if the incentives need to be fetched again, either because the data is not up to date or the display status has changed
    */
    async refresh() {
      const userStore = useUserStore();
      // console.log('refreshing ratings with scope', userStore.currentSelection.scope)
      // create a signature to see if the data is is up to date or needs to be fetched
      let signature = '';
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          signature = `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`;
          break;
        case 'salesTeam':
          signature = `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`;
          break;
        case 'campaign':
          signature = `campaign:${userStore.currentSelection.campaign.id}`;
          break;
      }
      const ratingExists = this.ratings.find((rating) => rating.signature === signature);
      // return the existing data if it exists and its update property is less then 6 hours old
      if (ratingExists && ratingExists?.update > (Date.now() - (6 * 60 * 60 * 1000))) return ratingExists;
      // fetch the data from the backend, get both halftime and campaign data
      const taskList = [
        fetchFromBackend("businessPartners/v1/getSalesAgentRatings", {
          id: userStore.id,
          filter: {
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgent: userStore.currentSelection.salesAgent.id,
            idSalesTeam: userStore.currentSelection.salesTeam.id
          },
          view: {
            pageSize: 12,
            page: 1
          }
        }),
      ];
      userStore.currentSelection.ratings.isLoading = true;
      const responses = await Promise.all(taskList)
      userStore.currentSelection.ratings.isLoading = false;

      const ratingData = {};
      for (const [index, segment] of ['week'].entries()) {
        ratingData[segment] = responses[index];
      }
      // if the data exists update it, if not create it
      if (ratingExists) {
        Object.assign(ratingExists, { update: Date.now(), data: ratingData });
      } else {
        // console.log('pushing rating', ratingData)
        this.ratings.push({ signature: signature, update: Date.now(), data: ratingData })
      }
    },
  },
});
