<script setup>
    import Reminder from '@/components/Reminder.vue'
    import { computed } from 'vue'
    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();
    
    import { usePerformanceStore } from '@/stores/performance'
    const performanceStore = usePerformanceStore();

    function createClassNameForStatus(statusName) {
        return statusName.toLowerCase().replace(' ', '-');
    }

    function formattedSalesAgentName(name) {
    return name
        .toLowerCase() // Konvertiere in Kleinbuchstaben
        .replace(/ä/g, 'ae') // Ersetze ä durch ae
        .replace(/ö/g, 'oe') // Ersetze ö durch oe
        .replace(/ü/g, 'ue') // Ersetze ü durch ue
        .replace(/ß/g, 'ss') // Ersetze ß durch ss
        .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
        + '.webp'; // Füge die Dateiendung hinzu
    };

    // const displayedPerformance = computed(() => {
    //     return performanceStore.performance[userStore.currentSelection.performance.display];
    // })

    const displayedPerformance = function(display) {
        const performance = performanceStore.performance[display];
        for (const status of Object.values(performance)) {
            // console.log('status', status);
            // status.currentStatus = createClassNameForStatus(status.name);
            // status.plannedStatus = createClassNameForStatus(status.name);
        }
        return performance;
    }

    const halftimeAchievedComission = computed(() => {
        for (const status of performanceStore.performance.halftime.status.statusList) {
            if (status.achieved.commission === true) return status.commission
        }
        return 0;
    });

    const halftimeAchievedComissionOnTop = computed(() => {
        for (const status of performanceStore.performance.halftime.status.statusList) {
            if (status.achieved.commissionOnTop === true) return status.commissionOnTop
        }
        return 0;
    });

    const campaignAchievedComission = computed(() => {
        for (const status of performanceStore.performance.campaign.status.statusList) {
            if (status.achieved === true) return status.commission
        }
        return 0;
    });

    const campaignAchievedComissionOnTop = computed(() => {
        for (const status of performanceStore.performance.campaign.status.statusList) {
            if (status.achieved === true) return status.commissionOnTop
        }
        return 0;
    });

    const targetStatusIsAchieved = function(display) {
        const performance = performanceStore.performance[display];
        return  performance.salesEuro?.expired >= 100 && (performance.salesEuroNewCustomers?.expired >= 100 || performance.salesNumberNewCustomers?.expired >= 100)
    }

    const salesVolumeIsAchieved = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuro.expired >= 100;
    }
    const salesVolumeIsPositive = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuro.expired >= performance.progress.expired || performance.salesEuro.expired >= 100
    }
    const salesVolumeIsNegative = function(display) {
        const performance = performanceStore.performance[display];
        return  performance.salesEuro.expired < performance.progress.expired && performance.salesEuro.expired < 100
    }

    const newCustomersIsAchieved = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuroNewCustomers?.expired >= 100;
    }
    const newCustomersIsBothAchieved = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuroNewCustomers.expired >= 100 && performance.salesEuro?.expired >= 100
    }
    const newCustomersIsInactive = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesNumberNewCustomers?.current >= performance.salesNumberNewCustomers?.planned
    }
    const newCustomersIsPositive = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuroNewCustomers.expired >= performance.progress.expired || performance.salesEuroNewCustomers.expired >= 100
    }
    const newCustomersIsNegative = function(display) {
        const performance = performanceStore.performance[display];
        return  performance.salesEuroNewCustomers?.expired < performance.progress.expired && performance.salesEuroNewCustomers.expired < 100
    }

    const newCustomersSalesNumberIsAchieved = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesNumberNewCustomers?.expired >= 100
    }
    const newCustomersSalesNumberIsInactive = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuroNewCustomers?.current >= performance.salesEuroNewCustomers?.planned
    }
    const newCustomersSalesNumberHalftimeIsPositive = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuro.expired >= performance.progress.expired || performance.salesEuro.expired >= 100
    }    
    const newCustomersSalesNumberCampaignIsPositive = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesNumberNewCustomers.expired >= performance.progress.expired || performance.salesNumberNewCustomers.expired >= 100
    }
    const newCustomersSalesNumberHalftimeIsNegative = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesEuro.expired < performance.progress.expired && performance.salesEuro.expired < 100
    }    
    const newCustomersSalesNumberCampaignIsNegative = function(display) {
        const performance = performanceStore.performance[display];
        return performance.salesNumberNewCustomers.expired < performance.progress.expired && performance.salesNumberNewCustomers.expired < 100
    }

    const targetStatusIsInactive = function(display) {
        return (display === 'halftime') ? true : false;
    }

    const targetStatusIsIncomplete = function(display) {
        return !userStore.status.achieved;
    }

    const currentStatus = function(display) {
        return performanceStore.performance[display].status.currentStatus.toLowerCase().replace(' ', '-');
    } 

    const plannedStatus = function(display) {
        return performanceStore.performance[display].status.plannedStatus.toLowerCase().replace(' ', '-');
    } 

    async function setDisplayStatus(display) {
        await userStore.selectDisplayStatus('performance', display);
    }

    function formatEuroValues(value) {
        return value.toLocaleString('de-DE');
    }

    const incentiveInformations = computed(() => {
        const championsExists = incentivesStore.incentive.salesAgents.champions.find((champion) => champion.highlightedPosition === true);
        if (!championsExists) {
            return {
                bonus: 0,
                entitled: false,
                name: '',
                rank: 0,
            }
        } else {
            return {
                bonus: championsExists.salesBonus,
                entitled: championsExists.salesBonusEntitled,
                name: championsExists.salesAgentName,
                rank: championsExists.rankLastImport,
            }
        }
    });

</script>

<template>
    <div class="reminder-wrapper singlepage-wrapper Performance max-width">
        <main id="container-main" class="dashboard">
            <div v-for="display in ['halftime','campaign']" :key="display">
                <div v-if="userStore.selectedMedia==='desktop' || userStore.currentSelection.performance.display===display" :class="['widget-wrapper performance', display,   {'desktopView' : userStore.selectedMedia==='desktop'}]">
                    <router-link to="/dashboard#performance">
                        <div class="headline-wrapper">
                            <h2 v-if='display === "halftime"'>Halbzeit</h2>
                            <h2 v-else>Kampagne</h2>
                        </div>
                    </router-link>
                    <div class="container-wrapper" v-if="!performanceStore.performance">
                        <div class="container main-content">
                            <div class="warning negative">Keine Daten verfügbar!</div>
                        </div>
                    </div>
                    <div v-else class="container-wrapper">
                        <div class="container main-content">
                            <div class="target-status-wrapper" :class="[{'achieved' : targetStatusIsAchieved(display)}, {'inactive' : targetStatusIsInactive(display)}, {'incomplete': targetStatusIsIncomplete(display)} ]">
                                <div class="status start">
                                    <div class="sm-wrapper">
                                        <div :class="['rank', currentStatus(display)]"><img :src="'avatars/' + formattedSalesAgentName(incentiveInformations.name)"
                                                alt="SM Profilbild"></div>
                                        <div class="centered">
                                            <p>{{displayedPerformance(display).status.currentStatus}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="status target">
                                    <p>Zielstatus</p>
                                    <div v-if="targetStatusIsAchieved(display)" class="icon-wrapper"><img src="@/assets/imgs/icons/circle-check-solid.svg"></div>
                                    <div v-else class="icon-wrapper"><img src="@/assets/imgs/icons/circle-xmark-solid.svg"></div>
                                </div>
                                <div class="status goal">
                                    <div class="sm-wrapper">
                                        <div :class="['rank', plannedStatus(display)]"><img :src="'avatars/' + formattedSalesAgentName(incentiveInformations.name)" alt="SM Profilbild"></div>
                                        <div class="centered">
                                            <p>{{displayedPerformance(display).status.plannedStatus}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bars-wrapper">
                                <span class="target-line" :style="{ width: Math.min(displayedPerformance(display).progress.expired, 100) + '%' }"></span>
                                <div class="bar-wrapper selling-time">
                                    <div class="txt-wrapper">
                                        <div><p class="txt-percentage">{{ displayedPerformance(display).progress.expired }}%</p><p>&nbsp;| Verkaufszeit</p></div>
                                        <!-- <p class="txt-centered">{{ displayedPerformance.progress?.current}}</p> -->
                                        <p>{{ displayedPerformance(display).progress.planned}} max. Termine</p>
                                    </div>
                                    <progress :value="displayedPerformance(display).progress.current" :max="displayedPerformance(display).progress.planned"></progress>
                                </div>
                                <div class="bar-wrapper sales-volume" :class="{ 'achieved' : salesVolumeIsAchieved(display), 'positive': salesVolumeIsPositive(display), 'negative': salesVolumeIsNegative(display)}">
                                    <div class="txt-wrapper">
                                        <div><p class="txt-percentage">{{displayedPerformance(display).salesEuro.expired}}%</p> <p>&nbsp;| Gesamt</p></div>
                                        <p class="txt-centered">{{formatEuroValues(displayedPerformance(display).salesEuro?.current)}}€</p>
                                        <p>{{formatEuroValues(displayedPerformance(display).salesEuro?.planned)}}€</p>
                                    </div>
                                    <progress :value="displayedPerformance(display).salesEuro.current" :max="displayedPerformance(display).salesEuro.planned"></progress>
                                </div>
                                <div class="bar-wrapper new-customer-sales" 
                                    :class="{ 
                                        'achieved' : newCustomersIsAchieved(display), 
                                        'both-achieved' : newCustomersIsBothAchieved(display), 
                                        'inactive': newCustomersIsInactive(display), 
                                        'positive': newCustomersIsPositive(display), 
                                        'negative': newCustomersIsNegative(display) 
                                    }">
                                    <div class="txt-wrapper">
                                        <div>
                                            <p class="txt-percentage">{{displayedPerformance(display).salesEuroNewCustomers.expired}}%</p> <p>&nbsp;| Neu-Kd.</p>
                                        </div>
                                        <p class="txt-centered">{{formatEuroValues(displayedPerformance(display).salesEuroNewCustomers.current)}}€</p>
                                        <p>{{formatEuroValues(displayedPerformance(display).salesEuroNewCustomers?.planned)}}€</p>
                                    </div>
                                    <progress :value="displayedPerformance(display).salesEuroNewCustomers.current" :max="displayedPerformance(display).salesEuroNewCustomers.planned"></progress>
                                </div>
                                <div v-if="display==='halftime'" 
                                    class="bar-wrapper dummy new-customer-sales-integer" 
                                    :class="{ 
                                        'inactive': newCustomersSalesNumberIsInactive(display), 
                                        'positive': newCustomersSalesNumberHalftimeIsPositive(display), 
                                        'negative':  newCustomersSalesNumberHalftimeIsNegative(display)
                                    }">
                                    <div class="txt-wrapper">
                                        <div><p>--%</p> <p>&nbsp;| Neu-Kd. #</p></div>
                                        <p class="txt-centered">--</p>
                                        <p >--</p>
                                    </div>
                                    <progress :value="0" :max="0"></progress>
                                </div>
                                <div v-else 
                                    class="bar-wrapper new-customer-sales-integer" 
                                    :class="{ 
                                        'achieved' : newCustomersSalesNumberIsAchieved(display), 
                                        'inactive': newCustomersSalesNumberIsInactive(display), 
                                        'positive': newCustomersSalesNumberCampaignIsPositive(display), 
                                        'negative': newCustomersSalesNumberCampaignIsNegative(display)
                                    }">
                                    <div class="txt-wrapper">
                                        <div><p class="txt-percentage">{{displayedPerformance(display).salesNumberNewCustomers?.expired}}%</p> <p>&nbsp;| Neu-Kd. #</p></div>
                                        <p class="txt-centered">{{displayedPerformance(display).salesNumberNewCustomers?.current}}</p>
                                        <p >{{displayedPerformance(display).salesNumberNewCustomers?.planned}}</p>
                                    </div>
                                    <progress :value="displayedPerformance(display).salesNumberNewCustomers?.current" :max="displayedPerformance(display).salesNumberNewCustomers?.planned"></progress>
                                </div>
                            </div>
                            <div class="accordion-menu">
                                <ul class="main-list">
                                    <li class="main-list-item">
                                        <input type="checkbox" checked>
                                        <i class="arrow"></i>
                                        <h3>Details</h3>
                                        <div class="details-wrapper">
                                            <div class="status">
                                                <h2>Elite Gold</h2>
                                                <div class="provisions-wrapper">
                                                    <div class="provision">
                                                        <p>Gesamt</p>
                                                        <p class="btn inactive not-achieved">1.000€</p>
                                                    </div>
                                                    <div class="provision">
                                                        <p>Neu-Kd.</p>
                                                        <p class="btn inactive not-achieved">1.000€</p>
                                                    </div>
                                                </div>
                                                <div class="bars-wrapper">
                                                    <div class="bar-wrapper sales-volume negative">
                                                        <div class="txt-wrapper">
                                                            <div><p>92%</p> <p>&nbsp;| Gesamt €</p></div>
                                                            <p class="txt-centered negative">-6.190€</p>
                                                            <p>75.000€</p>
                                                        </div>
                                                        <progress value="92" max="100"></progress>
                                                    </div>
                                                    <div class="bar-wrapper new-customer-sales-integer negative">
                                                        <div class="txt-wrapper">
                                                            <div><p>58%</p> <p>&nbsp;| Neu-Kd.</p></div>
                                                            <p class="txt-centered negative">-5300€</p>
                                                            <p>12.500€</p>
                                                        </div>
                                                        <progress value="3" max="10"></progress>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="status">
                                                <h2>Elite Silver</h2>
                                                <div class="provisions-wrapper">
                                                    <div class="provision">
                                                        <p>Gesamt</p>
                                                        <p class="btn inactive not-achieved">1.000€</p>
                                                    </div>
                                                    <div class="provision">
                                                        <p>Neu-Kd.</p>
                                                        <p class="btn inactive not-achieved">1.000€</p>
                                                    </div>
                                                </div>
                                                <div class="bars-wrapper">
                                                    <div class="bar-wrapper sales-volume negative">
                                                        <div class="txt-wrapper">
                                                            <div><p>92%</p> <p>&nbsp;| Gesamt €</p></div>
                                                            <p class="txt-centered negative">-6.190€</p>
                                                            <p>75.000€</p>
                                                        </div>
                                                        <progress value="92" max="100"></progress>
                                                    </div>
                                                    <div class="bar-wrapper new-customer-sales-integer negative">
                                                        <div class="txt-wrapper">
                                                            <div><p>58%</p> <p>&nbsp;| Neu-Kd.</p></div>
                                                            <p class="txt-centered negative">-5300€</p>
                                                            <p>12.500€</p>
                                                        </div>
                                                        <progress value="3" max="10"></progress>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="status current">
                                                <h2>Professional Gold</h2>
                                                <div class="provisions-wrapper">
                                                    <div class="provision">
                                                        <p>Gesamt</p>
                                                        <p class="btn inactive achieved">1.000€</p>
                                                    </div>
                                                    <div class="provision">
                                                        <p>Neu-Kd.</p>
                                                        <p class="btn inactive not-achieved">1.000€</p>
                                                    </div>
                                                </div>
                                                <div class="bars-wrapper">
                                                    <div class="bar-wrapper sales-volume positive">
                                                        <div class="txt-wrapper">
                                                            <div><p>106%</p> <p>&nbsp;| Gesamt €</p></div>
                                                            <p class="txt-centered positive">+3.890€</p>
                                                            <p>65.000€</p>
                                                        </div>
                                                        <progress value="100" max="100"></progress>
                                                    </div>
                                                    <div class="bar-wrapper new-customer-sales-integer negative">
                                                        <div class="txt-wrapper">
                                                            <div><p>58%</p> <p>&nbsp;| Neu-Kd.</p></div>
                                                            <p class="txt-centered negative">-5300€</p>
                                                            <p>12.500€</p>
                                                        </div>
                                                        <progress value="3" max="10"></progress>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="status">
                                                <h2>Professional Silver</h2>
                                                <div class="provisions-wrapper">
                                                    <div class="provision achieved">
                                                        <p>Gesamt</p>
                                                        <p class="btn inactive achieved">1.000€</p>
                                                    </div>
                                                    <div class="provision">
                                                        <p>Neu-Kd.</p>
                                                        <p class="btn inactive not-achieved">1.000€</p>
                                                    </div>
                                                </div>
                                                <div class="bars-wrapper">
                                                    <div class="bar-wrapper sales-volume positive achieved">
                                                        <div class="txt-wrapper">
                                                            <div><p>106%</p> <p>&nbsp;| Gesamt €</p></div>
                                                            <p class="txt-centered positive">+6.190€</p>
                                                            <p>65.000€</p>
                                                        </div>
                                                        <progress value="100" max="100"></progress>
                                                    </div>
                                                    <div class="bar-wrapper new-customer-sales-integer negative">
                                                        <div class="txt-wrapper">
                                                            <div><p>96%</p> <p>&nbsp;| Neu-Kd.</p></div>
                                                            <p class="txt-centered negative">-5300€</p>
                                                            <p>12.500€</p>
                                                        </div>
                                                        <progress value="96" max="100"></progress>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="value-container">
                            <div class="container value-wrapper">
                                <div class="headline-wrapper">
                                    <h3 v-if="display==='halftime'">Halbzeit Prämie<sup>*3</sup></h3> 
                                    <h3 v-else>Status-LP<sup>*3</sup></h3> 
                                    <div :class="['value-box', {'fulfilled' : displayedPerformance(display).status.commission > 0}]">{{formatEuroValues(displayedPerformance(display).status.commission)}}€</div>
                                </div>
                                <ul>
                                    <!-- <li class="achieved" v-for="n in 2" :key="n"> -->
                                    <li v-if="display==='halftime'" :class="['left', {'achieved' : halftimeAchievedComission > 0, 'not-achieved' : halftimeAchievedComission === 0 }]">
                                        <p>auf Gesamt</p>
                                        <p>{{formatEuroValues(halftimeAchievedComission)}}€</p>
                                    </li>
                                    <li v-else :class="['left', {'achieved' : campaignAchievedComission > 0, 'not-achieved' : campaignAchievedComission === 0 }]">
                                        <p >LP</p>
                                        <p>{{formatEuroValues(campaignAchievedComission)}}€</p>
                                    </li>

                                    <li v-if="display==='halftime'" :class="['left', {'achieved' : halftimeAchievedComissionOnTop > 0, 'not-achieved' : halftimeAchievedComissionOnTop === 0 }]">
                                        <p>Neu-Kd.</p>
                                        <p>{{formatEuroValues(halftimeAchievedComissionOnTop)}}€</p>
                                    </li>
                                    <li v-else :class="['left', {'achieved' : campaignAchievedComissionOnTop > 0, 'not-achieved' : campaignAchievedComissionOnTop === 0 }]">
                                        <p >LP incl. Touren LP</p>
                                        <p>{{formatEuroValues(campaignAchievedComissionOnTop)}}€</p>
                                    </li>
                                </ul>
                                <p class="distribution">Auszahlung: {{ incentivesStore.incentive.dateEndMonth }} {{ new Date().getFullYear() }}</p>
                            </div>
                            <div class="container value-wrapper">
                                <div class="headline-wrapper">
                                    <h3>Champions Bonus<sup>*1</sup></h3> 
                                    <div :class="['value-box', {'fulfilled' : incentiveInformations.entitled === true}]">{{ formatEuroValues(incentiveInformations.bonus) }}€</div>
                                </div>
                                <ul>
                                    <li :class="[{'not-achieved' : incentiveInformations.entitled === false}]" v-for="n in 1" :key="n">
                                        <p>Champions: Rang {{ incentiveInformations.rank }}</p>
                                        <p>{{ formatEuroValues(incentiveInformations.bonus) }}€</p>
                                    </li>
                                </ul>
                                <p class="distribution">Auszahlung: {{ incentivesStore.incentive.dateEndMonth }} {{ new Date().getFullYear() }}</p>
                            </div>
                        </div>
                    </div>
                    <div class='wave-wrapper'>
                        <div class='wave -one'></div>
                        <div class='wave -two'></div>
                        <div class='wave -three'></div>
                    </div>
                    <!-- TODO: skip the toggle wrapper on desktop -->
                    <div v-if="userStore.selectedMedia!=='desktop'" class="toggle-wrapper">
                        <div class="toggle-option" @click="setDisplayStatus('halftime')">
                            <span class="toggle-label">Halbzeit</span>
                            <label class="switch">
                                <input type="radio" name="performance-toggle" :checked="userStore.currentSelection.performance.display==='halftime'">
                                <span class="custom-radio"></span>
                            </label>
                        </div>
                        <div class="toggle-option" @click="setDisplayStatus('campaign')">
                            <span class="toggle-label">Kampagne</span>
                            <label class="switch">
                                <input type="radio" name="performance-toggle" :checked="userStore.currentSelection.performance.display==='campaign'">
                                <span class="custom-radio"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="userStore.selectedMedia==='desktop' || userStore.currentSelection.performance.display===display" :class="['widget-wrapper performance', display, {'desktopView' : userStore.selectedMedia==='desktop'}]">
                    <div class="headline-wrapper">
                        <h2 v-if='display === "halftime"'>Halbzeit Details</h2>
                        <h2 v-else>Kampagnen Details</h2>
                    </div>
                    <div class="container-wrapper">
                        <div class="container details">
                            <div class="details-wrapper">
                                <div v-for="(status, index) in displayedPerformance(display).status.statusList.slice(0, -1)" :key="index" :class="['status', {'current' : (display === 'campaign' && status.achieved) || (display === 'halftime' && (status.achieved.commission || status.achieved.commissionOnTop))}]">
                                    <div class="headline-wrapper">
                                        <div v-if="display==='campaign'" class="sm-wrapper nav">
                                            <div :class="['rank', createClassNameForStatus(status.name)]">
                                                <img :src="'avatars/' + formattedSalesAgentName(incentiveInformations.name)" alt="SM Profilbild">
                                            </div>
                                        </div>
                                        <div class="txt-wrapper"> 
                                            <h2>{{status.name}}</h2>
                                        </div>
                                    </div>
                                    <template v-if='display === "campaign"'>
                                        <div class="provisions-wrapper">
                                            <div class="provision">
                                                <!-- <p>Gesamt</p> -->
                                                <p v-if="status.plannedValues.salesEuro > 0" :class="['btn inactive top-right', { 'achieved': (displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100 >= 100, 'not-achieved': (displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100 < 100 }]">{{formatEuroValues(status.plannedValues.salesEuro)}}€</p>
                                                <p v-else :class="['btn inactive', { 'pointSystem-off': (status.plannedValues.salesEuro) > 0}]">--</p>
                                            </div>
                                            <div class="provision">
                                                <!-- <p>Neu-Kd.</p> -->
                                                <p :class="['btn inactive top-right', { 'achieved': (displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100 >= 100, 'not-achieved': (displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100 < 100 }]">{{formatEuroValues(status.commissionOnTop)}}€</p>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="provisions-wrapper">
                                            <div v-if="status.plannedValues.salesEuro > 0" class="provision">
                                                <!-- <p>Gesamt</p> -->
                                                <p :class="['btn inactive top-right', { 'achieved': (displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100 >= 100, 'not-achieved': (displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100 < 100 }]">{{formatEuroValues(status.commission)}}€</p>
                                            </div>
                                            <div v-else class="provision">
                                                <!-- <p>Gesamt</p> -->
                                                <p :class="['btn inactive pointSystem-off']">--</p>
                                            </div>
                                            <div class="provision">
                                                <!-- <p>Neu-Kd.</p> -->
                                                <p :class="['btn inactive top-right', { 'achieved': (displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100 >= 100, 'not-achieved': (displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100 < 100 }]">{{formatEuroValues(status.commissionOnTop)}}€</p>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="bars-wrapper">
                                        <div v-if="status.plannedValues.salesEuro > 0" class="bar-wrapper sales-volume">
                                            <div :class="['txt-wrapper', { 'positive': (displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100 >= 100, 'negative': (displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100 < 100 }]">
                                                <div><p>{{ Math.round((displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100) }}%</p></div>
                                                <p :class="['txt-centered', { 'positive': Math.round((displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100) >= 100, 'negative': Math.round((displayedPerformance(display).salesEuro?.current / status.plannedValues.salesEuro) * 100) < 100 }]">
                                                    {{ 
                                                        (displayedPerformance(display).salesEuro?.current - status.plannedValues.salesEuro) >= 0 
                                                        ? '+' + formatEuroValues(displayedPerformance(display).salesEuro?.current - status.plannedValues.salesEuro) 
                                                        : formatEuroValues(displayedPerformance(display).salesEuro?.current - status.plannedValues.salesEuro) 
                                                    }}€
                                                </p>
                                                <p>{{formatEuroValues(status.plannedValues.salesEuro)}}€</p>
                                            </div>
                                            <progress :value="displayedPerformance(display).salesEuro?.current" :max="status.plannedValues.salesEuro"></progress>
                                        </div>
                                        <div v-else class="bar-wrapper sales-volume">
                                            <div :class="['txt-wrapper pointSystem-off']">
                                                <div><p>--%</p></div>
                                                <p :class="['txt-centered']">
                                                   --
                                                </p>
                                                <p>--</p>
                                            </div>
                                            <progress :value="displayedPerformance(display).salesEuro?.current" :max="status.plannedValues.salesEuro"></progress>
                                        </div>
                                        <div class="bar-wrapper new-customer-sales-integer">
                                            <div :class="['txt-wrapper', { 'positive': (displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100 >= 100, 'negative': (displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100 < 100 }]">
                                                <div><p>{{ Math.round((displayedPerformance(display).salesEuroNewCustomers?.current / status.plannedValues.salesEuroNewCustomers) * 100) }}%</p></div>
                                                <p class="txt-centered"> 
                                                    {{ 
                                                        (displayedPerformance(display).salesEuroNewCustomers?.current - status.plannedValues.salesEuroNewCustomers) >= 0 
                                                        ? '+' + formatEuroValues(displayedPerformance(display).salesEuroNewCustomers?.current - status.plannedValues.salesEuroNewCustomers) 
                                                        : formatEuroValues(displayedPerformance(display).salesEuroNewCustomers?.current - status.plannedValues.salesEuroNewCustomers) 
                                                    }}€
                                                </p>
                                                <p>{{formatEuroValues(status.plannedValues.salesEuroNewCustomers)}}€</p>
                                            </div>
                                            <progress :value="displayedPerformance(display).salesEuroNewCustomers?.current" :max="status.plannedValues.salesEuroNewCustomers"></progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='wave-wrapper'>
                        <div class='wave -one'></div>
                        <div class='wave -two'></div>
                        <div class='wave -three'></div>
                    </div>
                </div>
            </div>
        </main>
        <reminder></reminder>
    </div>
</template>