import { defineStore } from "pinia";

import { useUserStore } from "./user";

import { setKey as setBrowserStorageKey } from "./helpers/localStorage.mjs";
import { fetchFromBackend } from "./helpers/backend.mjs";

export const useActivityStore = defineStore("activity", {
  state: () => {
    return {
      activities: [],
    }
  },
  getters: {
    // return the full activity data, the signature allows for finding the activity by display (i.e. week or month) and campaign.id
    activity: (state) => {
      if (!state.activities) return false;
      const userStore = useUserStore();
      let activity = null;
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          activity = state.activities.find((activity) => activity.signature === `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'salesTeam':
          activity = state.activities.find((activity) => activity.signature === `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'campaign':
          activity = state.activities.find((activity) => activity.signature === `campaign:${userStore.currentSelection.campaign.id}`);
          break;
      }
      if (!activity) return false;
      return activity.data;
    },
    week: (state) => {
      const userStore = useUserStore();
      let activity = null;
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          activity = state.activities.find((activity) => activity.signature === `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'salesTeam':
          activity = state.activities.find((activity) => activity.signature === `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'campaign':
          activity = state.activities.find((activity) => activity.signature === `campaign:${userStore.currentSelection.campaign.id}`);
          break;
      }
      if (!activity) return false;
      return activity.data.week.find((unit) => unit.id === userStore.currentSelection.activity.week);
    },
    month: (state) => {
      const userStore = useUserStore();
      let activity = null;
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          activity = state.activities.find((activity) => activity.signature === `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'salesTeam':
          activity = state.activities.find((activity) => activity.signature === `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'campaign':
          activity = state.activities.find((activity) => activity.signature === `campaign:${userStore.currentSelection.campaign.id}`);
          break;
      }
      if (!activity) return false;
      return activity.data.month.find((unit) => unit.id === userStore.currentSelection.activity.month);
    },
    halftime: (state) => {
      const userStore = useUserStore();
      let activity = null;
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          activity = state.activities.find((activity) => activity.signature === `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'salesTeam':
          activity = state.activities.find((activity) => activity.signature === `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'campaign':
          activity = state.activities.find((activity) => activity.signature === `campaign:${userStore.currentSelection.campaign.id}`);
          break;
      }
      if (!activity) return false;
      return activity.data['periodEnd:ht'][0];
    },
    campaign: (state) => {
      const userStore = useUserStore();
      let activity = null;
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          activity = state.activities.find((activity) => activity.signature === `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'salesTeam':
          activity = state.activities.find((activity) => activity.signature === `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`);
          break;
        case 'campaign':
          activity = state.activities.find((activity) => activity.signature === `campaign:${userStore.currentSelection.campaign.id}`);
          break;
      }
      if (!activity) return false;
      return activity.data['periodEnd'][0];
    }
  },
  actions: {
    /** see if the incentives need to be fetched again, either because the data is not up to date or the display status has changed
    */
    async refresh() {
      const userStore = useUserStore();
      // create a signature to see if the data is is up to date or needs to be fetched
      let signature = '';
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          signature = `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`;
          break;
        case 'salesTeam':
          signature = `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`;
          break;
        case 'campaign':
          signature = `campaign:${userStore.currentSelection.campaign.id}`;
          break;
      }
      const activityExists = this.activities.find((activity) => activity.signature === signature);
      // return the existing data if it exists and its update property is less then 6 hours old
      if (activityExists && activityExists?.update > (Date.now() - (6 * 60 * 60 * 1000))) return activityExists;
      // fetch the data from the backend, get both halftime and campaign data
      const taskList = [
        fetchFromBackend("activityMonitor/v1/salesAgents", {
          id: userStore.id,
          filter: {
            mode: 'week',
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgents: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesAgent.id,
            idSalesTeams: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesTeam.id,
          }
        }),
        fetchFromBackend("activityMonitor/v1/salesAgents", {
          id: userStore.id,
          filter: {
            mode: 'month',
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgents: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesAgent.id,
            idSalesTeams: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesTeam.id,
          }
        }),
        fetchFromBackend("activityMonitor/v1/salesAgents", {
          id: userStore.id,
          filter: {
            mode: 'periodEnd:ht',
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgents: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesAgent.id,
            idSalesTeams: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesTeam.id,
          }
        }),
        fetchFromBackend("activityMonitor/v1/salesAgents", {
          id: userStore.id,
          filter: {
            mode: 'period',
            idCompany: userStore.currentSelection.campaign.company.id,
            idCampaign: userStore.currentSelection.campaign.id,
            idSalesAgents: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesAgent.id,
            idSalesTeams: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesTeam.id,
          }
        }),
      ];
      userStore.currentSelection.activity.isLoading = true;
      const responses = await Promise.all(taskList)
      userStore.currentSelection.activity.isLoading = false;

      const activityData = {};
      for (const [index, segment] of ['week', 'month', 'periodEnd:ht', 'periodEnd'].entries()) {
        activityData[segment] = responses[index].units;
        if (segment === 'month') activityData[`levelList`] = responses[index].levelList;
        for (const unit of activityData[segment]) {
          if (responses[index].currentUnit == unit.id) {
            unit.isCurrent = true;
            userStore.currentSelection.activity[segment] = unit.id;
          }
        }
      }
      // if the data exists update it, if not create it
      if (activityExists) {
        Object.assign(activityExists, { update: Date.now(), data: activityData });
      } else {
        this.activities.push({ signature: signature, update: Date.now(), data: activityData });
      }
    },
    /** see if the incentives need to be fetched again, either because the data is not up to date or the display status has changed
    */
    async refreshMode(mode) {
      const userStore = useUserStore();
      // create a signature to see if the data is is up to date or needs to be fetched
      let signature = '';
      switch (userStore.currentSelection.scope) {
        case 'salesAgent':
          signature = `salesAgent:${userStore.currentSelection.salesAgent.id}:${userStore.currentSelection.campaign.id}`;
          break;
        case 'salesTeam':
          signature = `salesTeam:${userStore.currentSelection.salesTeam.id}:${userStore.currentSelection.campaign.id}`;
          break;
        case 'campaign':
          signature = `campaign:${userStore.currentSelection.campaign.id}`;
          break;
      }
      const activityExists = this.activities.find((activity) => activity.signature === signature);
      if (!activityExists) return false;
      // fetch the data from the backend, get both halftime and campaign data
      userStore.currentSelection.activity.isLoading = true;
      const response = await fetchFromBackend("activityMonitor/v1/salesAgents", {
        id: userStore.id,
        filter: {
          mode: mode,
          idCompany: userStore.currentSelection.campaign.company.id,
          idCampaign: userStore.currentSelection.campaign.id,
          idSalesAgents: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesAgent.id,
          idSalesTeams: (userStore.isSalesAgent) ? -1 : userStore.currentSelection.salesTeam.id,
        }
      });
      userStore.currentSelection.activity.isLoading = false;

      activityExists.data[mode] = response.units;
      if (mode === 'month') activityExists.data[`levelList`] = response.levelList;
      for (const unit of activityExists.data[mode]) {
        if (response.currentUnit == unit.id) {
          unit.isCurrent = true;
          userStore.currentSelection.activity[mode] = unit.id;
        }
      }
    },
  },
});
