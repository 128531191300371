<script setup>

    import { computed } from 'vue'
    import Reminder from '@/components/Reminder.vue'

    import { useUserStore } from '@/stores/user'
    const userStore = useUserStore();

    import { useRatingsStore } from '../../stores/ratings';
    const ratingsStore = useRatingsStore();

    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
    };

    function formattedSalesAgentName(name) {
    return name
        .toLowerCase() // Konvertiere in Kleinbuchstaben
        .replace(/ä/g, 'ae') // Ersetze ä durch ae
        .replace(/ö/g, 'oe') // Ersetze ö durch oe
        .replace(/ü/g, 'ue') // Ersetze ü durch ue
        .replace(/ß/g, 'ss') // Ersetze ß durch ss
        .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
        + '.webp'; // Füge die Dateiendung hinzu
    };

    const isCurrentWeek = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        
        // Set the start of the current week to the previous Monday
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1));
        startOfWeek.setHours(0, 0, 0, 0);
        
        // Set the end of the current week to the next Sunday
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999);
        
        return date >= startOfWeek && date <= endOfWeek;
    };

    const currentWeekRatingsCount = computed(() => {
        return ratingsStore.rating[userStore.currentSelection.ratings.display].filter(rating => isCurrentWeek(rating.rating.captured)).length;
    });
</script>

<template>
    <div>
        <div class="reminder-wrapper singlepage-wrapper max-width">
            <main id="container-main" class="dashboard">
                <div class="evaluation-wrapper widget-wrapper active">
                    <div class="mb_form_main_wrapper">
                        <div class="legende evaluation">
                            <div class="section">
                                <router-link to="/dashboard#reviews">
                                    <div class="headline-wrapper">
                                        <h2>Bewertungen</h2>
                                    </div>
                                </router-link>
                                <p>Hier findet man die Übersicht aller Bewertungen!</p>
                            </div>
                            <hr>
                            <div class="section">
                                <h3>Sales Manager</h3>
                                <p>Alle Bewertungen des Sales Managers!</p>
                                <div class="img-wrapper">
                                    <span></span>
                                    <div class=""><img src=""></div>
                                    <span></span>
                                </div>
                                <label for="sales-agents"></label>
                                <select id="sales-agents" name="sales-agents">
                                    <option value="volvo">-- Alle --</option>
                                </select>
                                <button class="active">Aktiv</button>
                            </div>
                            <div class='box'>
                                <div class='wave -one'> </div>
                                <div class='wave -two'></div>
                            </div>
                            <hr>
                        </div>
                        <div class="ratings-wrapper container">
                            <div class="header-wrapper">
                                <div class="rating-row">
                                    <div class="integer-latest-entrys">
                                        <div>Dieswöchige Bewertungen:</div> 
                                        <span>{{0}}</span>
                                    </div>
                                    <div class="questions">
                                        <span>Stattgefunden <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg><p>Hat der Termin zum vereinbarten Zeitpunkt stattgefunden?</p></span>
                                        <span>vertrauenswürdig? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg><p>War Ihr Mediaberater freundlich und das Auftreten insgesamt vertrauenswürdig?</p></span>
                                        <span>Kundenorientiert? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg> <p>War Ihr Mediaberater kunden-/lösungsorientiert?</p></span>
                                        <span>zufriedenstellend? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg> <p>Wurden die in Aussicht gestellten Themen zufriedenstellend besprochen?</p></span>
                                        <span>überzeugt? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg> <p>Hat Sie unser Angebot überzeugt und haben Sie davon Gebrauch gemacht? </p></span>
                                        <span>weiterempfehlen? <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg> <p>Würden Sie Sellwerk weiterempfehlen?</p></span>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div v-if="userStore.currentSelection.ratings.isLoading === true" class="container main-content">
                                <div class="loader" id="loader"></div>
                            </div>
                            <div v-else v-for="rating of ratingsStore.rating[userStore.currentSelection.ratings.display].ratings" :key="rating.businessPartner.GPNo" :class="['rating-row', { 'current-week': isCurrentWeek(rating.rating.captured) }]">
                                <div class="content-wrapper">
                                    <div class="bewertung-whole-wrapper">
                                        <div class="badges-wrapper">
                                            <span class="date">{{formatDate(rating.rating.captured)}}</span>
                                            <span class="gp">GP: &nbsp; {{rating.businessPartner.GPNo}}</span>
                                        </div>
                                        <div class="salesManager">
                                            <div class="sm-wrapper nav">
                                                <div :class="['rank rating-4', userStore.status?.current?.toLowerCase().replaceAll(' ','-')]">
                                                    <!-- <p class="rating-value">{{ 4.3 }}</p> -->
                                                    <img :src="'avatars/' + formattedSalesAgentName(rating.salesAgent.firstname + ' ' + rating.salesAgent.surname)" alt="SM Profilbild">
                                                </div>
                                            </div>
                                            <div class="informations-wrapper">
                                                <div class="txt-wrapper">
                                                    <p><b>{{rating.salesAgent.firstname + ' ' + rating.salesAgent.surname}}</b></p>
                                                    <i>{{rating.salesAgent.salesAgentNo}}</i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="star-wrapper">
                                        <div class="star-wrapper-top">
                                            <div class="value-wrapper"><span>Stattgefunden?</span><p class="question positive">{{rating.rating.checkbox_01}}</p></div>
                                            <div class="value-wrapper"><span>Vertrauenswürdig?</span><p :class="['_' + rating.rating.average_01_01]">{{rating.rating.average_01_01}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                                            <div class="value-wrapper"><span>Kundenorientiert?</span><p :class="['_' + rating.rating.average_02_02]">{{rating.rating.average_02_02}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                                        </div>
                                        <div class="star-wrapper-bottom">
                                            <div class="value-wrapper"><span>Überzeugt?</span><p class="question negative">{{rating.rating.checkbox_02}}</p></div>
                                            <div class="value-wrapper"><span>Zufriedenstellend?</span><p :class="['_' + rating.rating.average_03_03]">{{rating.rating.average_03_03}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                                            <div class="value-wrapper"><span>Weiterempfehlung?</span><p :class="['_' + rating.rating.average_04_04]">{{rating.rating.average_04_04}}<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg> </p></div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </main>
            <reminder></reminder>
        </div>
    </div>
</template>

<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->