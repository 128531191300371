async function fetchFromBackend(endpoint, payload) {
    // console.log(`fetching ${endpoint} with payload: ${JSON.stringify(payload)}`);
    const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/${endpoint}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    });
    return await response.json();
}
export { fetchFromBackend };
