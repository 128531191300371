import { ref } from "vue";

const storageKey = "theme-preference";

const getColorPreference = () => {
    const storedPreference = localStorage.getItem(storageKey);
    if (storedPreference) {
        return storedPreference;
    } else {
        return window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light";
    }
};

const theme = ref(getColorPreference());

const setPreference = () => {
    localStorage.setItem(storageKey, theme.value);
    reflectPreference();
};

const reflectPreference = () => {
    document.documentElement.setAttribute("data-theme", theme.value);
    document
        .querySelector("#theme-toggle")
        ?.setAttribute("aria-label", theme.value);

    const moonElement = document.querySelector(".moon");
    const sunElement = document.querySelector(".sun");
    // console.log('init', theme.value);
    if (theme.value === "dark") {
        //console.log('add dark');
        moonElement?.classList.add("visible");
        sunElement?.classList.remove("visible");
    } else {
        //console.log('add light');
        moonElement?.classList.remove("visible");
        sunElement?.classList.add("visible");
    }
};

const onClick = () => {
    const moonElement = document.querySelector(".moon");
    const sunElement = document.querySelector(".sun");

    if (moonElement) {
        moonElement.classList.toggle("visible");
    }
    if (sunElement) {
        sunElement.classList.toggle("visible");
    }

    // Flip current value
    theme.value = theme.value === "light" ? "dark" : "light";

    setPreference();
};

// // Set early so no page flashes / CSS is made aware
// reflectPreference();

// onMounted(() => {
//     // Set on load so screen readers can see latest value on the button
//     reflectPreference();

//     // Now this script can find and listen for clicks on the control
//     document.querySelector("#theme-toggle").addEventListener("click", onClick);
// });

// Sync with system changes
window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", ({ matches: isDark }) => {
        theme.value = isDark ? "dark" : "light";
        setPreference();
    });

export { theme, setPreference, reflectPreference, onClick };
