<script setup>
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import Navigation from '@/components/Nav.vue'
  import Reminder from '@/components/Reminder.vue'
  
  const route = useRoute();
  const isLoginPage = computed(() => route.name === 'Login');
</script>
<!-- version 0.95 -->
<template>
  <div>
    <navigation v-if="!isLoginPage"></navigation>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
  @import '@/scss/style.scss';
</style>