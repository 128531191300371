<script setup>
    import { ref, onMounted } from 'vue'
    import Reminder from '@/components/Reminder.vue'
    
    import { useIncentivesStore } from '@/stores/incentives'
    const incentivesStore = useIncentivesStore();

    import { useUserStore } from '@/stores/user';
    const userStore = useUserStore();

    function formatEuroValues(value) {
        return value.toLocaleString('de-DE');
    }
    
    async function handleDisplayStatus(display) {
      await userStore.selectDisplayStatus('incentives', display);
    }

    function formattedSalesAgentName(name) {
    return name
        .toLowerCase() // Konvertiere in Kleinbuchstaben
        .replace(/ä/g, 'ae') // Ersetze ä durch ae
        .replace(/ö/g, 'oe') // Ersetze ö durch oe
        .replace(/ü/g, 'ue') // Ersetze ü durch ue
        .replace(/ß/g, 'ss') // Ersetze ß durch ss
        .replace(/\s+/g, '-') // Ersetze Leerzeichen durch -
        + '.webp'; // Füge die Dateiendung hinzu
    };

    const handleImageError = (event) => {
        event.target.src = 'avatars/dummy-avatar.webp';
    };

    const tableWrapper1 = ref(null);
    const tableWrapper2 = ref(null);

    onMounted(() => {
        if (tableWrapper1.value) {
            // console.log('tableWrapper1:', tableWrapper1.value); // Sollte das DOM-Element ausgeben
            // console.log('tableWrapper1.scrollLeft:', tableWrapper1.value[0].scrollLeft); // Sollte den aktuellen scrollLeft-Wert ausgeben
        } else {
            // console.error('tableWrapper1 ist nicht definiert');
        }

        if (tableWrapper2.value) {
            // console.log('tableWrapper2:', tableWrapper2.value); // Sollte das DOM-Element ausgeben
            // console.log('tableWrapper2.scrollLeft:', tableWrapper2.value[0].scrollLeft); // Sollte den aktuellen scrollLeft-Wert ausgeben
        } else {
            // console.error('tableWrapper2 ist nicht definiert');
        }
    });

    const syncScroll = (table) => {
        if (table === 'table1' && tableWrapper1.value && tableWrapper2.value) {
            tableWrapper2.value[0].scrollLeft = tableWrapper1.value[0].scrollLeft;
            // console.log('tableWrapper1:', tableWrapper1.value);
            // console.log('tableWrapper1.scrollLeft:', tableWrapper1.value[0].scrollLeft); // Sollte den aktuellen scrollLeft-Wert ausgeben
        } else if (table === 'table2' && tableWrapper1.value && tableWrapper2.value) {
            tableWrapper1.value[0].scrollLeft = tableWrapper2.value[0].scrollLeft;
            // console.log('tableWrapper2:', tableWrapper2.value);
            // console.log('tableWrapper2.scrollLeft:', tableWrapper2.value[0].scrollLeft); // Sollte den aktuellen scrollLeft-Wert ausgeben
        }
    };

</script>

<template>
    <div>
        <div class="reminder-wrapper singlepage-wrapper incentive max-width">
            <main id="container-main" class="dashboard">
                <div v-if="userStore.currentSelection.incentives.isLoading === true">
                    <div class="widget-wrapper">
                        <div class="container">
                            <div class="loader" id="loader"></div>
                        </div>
                    </div>
                </div>
                <div v-else-if="incentivesStore.incentives.length === 0" class="container">
                    <div class="widget-wrapper">
                        <div class="container">
                            <div class="warning">Keine Daten verfügbar!</div>
                        </div>
                    </div>
                </div>
                <div v-else v-for="display in ['salesAgents','salesTeams']" :key="display">
                    <div v-if="userStore.selectedMedia==='desktop' || userStore.currentSelection.incentives.display===display" :class="['widget-wrapper champions',  {'desktopView' : userStore.selectedMedia==='desktop'}]">
                        <router-link to="/dashboard#champions">
                            <div class="headline-wrapper">
                                <h2 v-if="display === 'salesAgents'">Sales Manager</h2>
                                <h2 v-if="display === 'salesTeams'">Areas</h2>
                                <p>{{ incentivesStore.incentive[display].name }} ({{incentivesStore.incentive[display].dateStart.split(', ')[0]}} - {{incentivesStore.incentive[display].dateEnd.split(', ')[0]}})</p>
                            </div>
                        </router-link>
                        <div class="container-wrapper">
                            <template v-if="incentivesStore.incentiveSales[display].length > 0">        
                                <div class="container toggle-me">
                                    <div class="winners-podium">
                                        <div :class="['winner-wrapper', {'highlighted' : entry.highlightedPosition === true}]" v-for="(entry, index) in incentivesStore.topThree[display]" :key="entry.id">
                                            <div :class="['winner', index === 0 ? '-second' : index === 1 ? '-first' : '-third', { 'logged-in': entry.highlightedPosition }]">
                                                <img @error="handleImageError" v-if="display === 'salesAgents'" :src="'avatars/' + formattedSalesAgentName(entry.salesAgentName)" alt="">
                                                <img @error="handleImageError" v-if="display === 'salesTeams'" :src="'avatars/' + formattedSalesAgentName(entry.salesTeamName)" alt="">
                                                <!-- <img src="@/assets/imgs/champions/dummy-avatar.png" alt=""> -->
                                            </div>
                                            <div class="txt-wrapper">
                                                <span v-if="display === 'salesAgents'">{{ entry.salesAgentName }}</span>
                                                <span v-if="display === 'salesTeams'">{{ entry.salesTeamName }}</span>
                                                <span v-if="incentivesStore.incentive[display].focus === 'salesEuroNew'">{{
                                        formatEuroValues(entry.salesEuroNew) }}€</span>
                                                <span v-if="incentivesStore.incentive[display].focus === 'salesEuro'">{{
                                        formatEuroValues(entry.salesEuro) }}€</span>
                                                <span v-if="entry.rankLastImport !== null" class="rank">{{ entry.rankLastImport }}</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="ranklist-wrapper">
                                        <div class="ranklist" ref="tableWrapper1" @scroll="syncScroll('table1')">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th v-if="display === 'salesAgents'">Sales Manager</th>
                                                        <th v-if="display === 'salesTeams'">Areas</th>
                                                        <template v-if="incentivesStore.incentive[display].focus === 'salesEuroNew'">
                                                            <th>Neu-Kd.</th>
                                                            <th>Umsatz</th>
                                                        </template>
                                                        <template v-if="incentivesStore.incentive[display].focus === 'salesEuro'">
                                                            <th>Umsatz</th>
                                                            <th>Neu-Kd.</th>
                                                        </template>
                                                        <th v-if="display === 'salesAgents'">Bonus <span class="asterisk">&#42;&sup1;</span></th>
                                                        <th v-if="display === 'salesTeams'">Teamkasse <span class="asterisk">&#42;&sup2;</span></th>
                                                    </tr>
                                                </thead>
                                                    <tbody v-for="(entry, index) in incentivesStore.incentiveSales[display]" :key="entry.id">
                                                        <tr :class="{ 'logged-in': entry.highlightedPosition }">
                                                            <td class="position" :class="{'rank-out': entry.rankChangeDirection === 'out', 'rank-new': entry.rankChangeDirection === 'new', 'rank-up': entry.rankChangeCount > 0, 'rank-hold': entry.rankChangeCount === '0', 'rank-down': entry.rankChangeCount < 0}">
                                                                <template v-if="entry.rankChangeDirection === 'out'">ST</template>
                                                                <template v-else>{{ entry.rankLastImport.toString().padStart(2,'0') }}</template>
                                                                <span class="change-count" v-if="entry.rankChangeDirection === 'new'">neu</span>
                                                                <span class="change-count" v-if="entry.rankChangeDirection === 'out'">storno</span>
                                                                <span class="change-count" v-if="entry.rankChangeCount !== '0'">{{ entry.rankChangeCount }}</span>
                                                                <span class="highlightBorder" v-if="entry.highlightedPosition === true"></span>
                                                            </td>
                                                            <td class="sm-wrapper">
                                                                <div
                                                                    :class="['rank', (entry.salesAgentStatusCurrent) ? entry.salesAgentStatusCurrent.toLowerCase().replaceAll(' ', '-') : '', 'rating-' + entry.rating]">
                                                                    <p class="rating-value">{{ entry.rating }}</p>
                                                                    <!-- <img src="@/assets/imgs/champions/dummy-avatar.png" alt=""> -->
                                                                    <img @error="handleImageError" v-if="display === 'salesAgents'" :src="'avatars/' + formattedSalesAgentName(entry.salesAgentName)" alt="">
                                                                    <img @error="handleImageError" v-if="display === 'salesTeams'" :src="'avatars/' + formattedSalesAgentName(entry.salesTeamName)" alt="">
                                                                    <div class="tooltip">
                                                                        <div class="sm-wrapper nav">
                                                                            <div class="rank professional-silver rating-4">
                                                                                <p class="rating-value">{{ 4.3 }}</p>
                                                                                <!-- <img src="@/assets/imgs/champions/dummy-avatar.png" alt=""> -->
                                                                                <img @error="handleImageError" v-if="display === 'salesAgents'" :src="'avatars/' + formattedSalesAgentName(entry.salesAgentName)" alt="">
                                                                                <img @error="handleImageError" v-if="display === 'salesTeams'" :src="'avatars/' + formattedSalesAgentName(entry.salesTeamName)" alt="">
                                                                            </div>
                                                                            <div class="txt-wrapper"> 
                                                                                <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p>
                                                                                <!-- <p class="sm-surname">{{userStore.status}}</p> -->
                                                                                <p class="sm-status">{{entry.salesAgentStatusCurrent}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="marquee" v-if="display === 'salesAgents'">
                                                                    <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p>
                                                                    <!-- <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p> -->
                                                                </div>
                                                                <div class="marquee" v-if="display === 'salesTeams'">
                                                                    <p>{{ entry.salesTeamName }}</p>
                                                                    <!-- <p>{{ entry.salesTeamName }}</p> -->
                                                                </div>
                                                            </td>
                                                            <template v-if="incentivesStore.incentive.salesAgents.focus==='salesEuroNew'">
                                                                <td>{{ formatEuroValues(entry.salesEuroNew) }}€</td>
                                                                <td>{{ formatEuroValues(entry.salesEuro) }}€</td>
                                                            </template>
                                                            <template v-if="incentivesStore.incentive.salesAgents.focus==='salesEuro'">
                                                                <td>{{ formatEuroValues(entry.salesEuro) }}€</td>
                                                                <td>{{ formatEuroValues(entry.salesEuroNew) }}€</td>
                                                            </template>
                                                            <td :class="{ 'achieved' : entry.salesBonusEntitled === true  && index < incentivesStore.incentive[display].maxChampions, 'not-achieved' : entry.salesBonusEntitled === false  && index < incentivesStore.incentive[display].maxChampions}">
                                                                {{ formatEuroValues(entry.salesBonus) }}€
                                                            </td>
                                                        </tr>
                                                        <tr v-if="index === incentivesStore.incentive[display].maxChampions - 1 && index < incentivesStore.incentiveSales[display].length - 1"  
                                                            class="separator">
                                                            <td v-for="i in [1,2,3,4,5]" :key="i">
                                                                <span></span>
                                                            </td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="incentivesStore.incentiveNoSales[display].length > 0" class="container out-of-rank">
                                    <div class="ranklist-wrapper">
                                        <div class="ranklist" ref="tableWrapper2" @scroll="syncScroll('table2')">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Rang</th>
                                                        <th v-if="display === 'salesAgents'">Sales Manager</th>
                                                        <th v-if="display === 'salesTeams'">Areas</th>
                                                        <template v-if="incentivesStore.incentive[display].focus === 'salesEuroNew'">
                                                            <th>Neu-Kd.</th>
                                                            <th>Umsatz</th>
                                                        </template>
                                                        <template v-if="incentivesStore.incentive[display].focus === 'salesEuro'">
                                                            <th>Umsatz</th>
                                                            <th>Neu-Kd.</th>
                                                        </template>
                                                        <th v-if="display === 'salesAgents'">Bonus<span class="asterisk">&#42;&sup1;</span></th>
                                                        <th v-if="display === 'salesTeams'">Teamkasse</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr :class="{ 'logged-in': entry.highlightedPosition }"
                                                        v-for="(entry, index) in incentivesStore.incentiveNoSales[display]" :key="index">
                                                        <td class="position"
                                                            :class="{ 'rank-out': entry.rankChangeDirection === 'out', 'rank-up': entry.rankChangeCount > 0, 'rank-hold': entry.rankChangeCount === 0, 'rank-down': entry.rankChangeCount < 0 }">
                                                            <template v-if="entry.rankChangeDirection === 'out'">ST</template>
                                                            <template v-else>--</template>
                                                            <span class="change-count"
                                                                v-if="entry.rankChangeDirection === 'new'">neu</span>
                                                            <span class="change-count"
                                                                v-if="entry.rankChangeDirection === 'out'">storno</span>
                                                            <span class="change-count" v-else>{{ entry.rankChangeCount }}</span>
                                                            <span class="highlightBorder" v-if="entry.highlightedPosition === true"></span>
                                                        </td>
                                                        <td class="sm-wrapper">
                                                            <div
                                                                :class="['rank', (entry.salesAgentStatusCurrent) ? entry.salesAgentStatusCurrent.toLowerCase().replaceAll(' ', '-') : '', 'rating-' + entry.rating]">
                                                                <p class="rating-value">{{ entry.rating }}</p>
                                                                <!-- <img src="@/assets/imgs/champions/dummy-avatar.png" alt=""> -->
                                                                <img @error="handleImageError" v-if="display === 'salesAgents'" :src="'avatars/' + formattedSalesAgentName(entry.salesAgentName)" alt="">
                                                                <img @error="handleImageError" v-if="display === 'salesTeams'" :src="'avatars/' + formattedSalesAgentName(entry.salesTeamName)" alt="">

                                                            </div>
                                                            <div class="marquee" v-if="display === 'salesAgents'">
                                                                <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p>
                                                                <!-- <p>{{ entry.salesAgentName }} [{{ entry.salesTeamName }}]</p> -->
                                                            </div>
                                                            <div class="marquee" v-if="display === 'salesTeams'">
                                                                <p>{{ entry.salesTeamName }}</p>
                                                                <!-- <p>{{ entry.salesTeamName }}</p> -->
                                                            </div>
                                                        </td>
                                                        <td>{{ formatEuroValues(entry.salesEuroNew) }}€</td>
                                                        <td>{{ formatEuroValues(entry.salesEuro) }}€</td>
                                                        <td>{{ formatEuroValues(entry.salesBonus) }}€</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- <hr> -->
                                    <!-- <p class="info"><sup>*1</sup>{{ incentivesStore.incentive[display].disclaimer }}</p> -->
                                </div>
                            </template>
                            <div v-else class="container">
                                <div class="warning">Noch keinen Umsatz geschrieben!</div>
                            </div>
                        </div>
                        <div class='wave-wrapper'>
                            <div class='wave -one'></div>
                            <div class='wave -two'></div>
                            <div class='wave -three'></div>
                        </div>
                        <!-- TODO: skip the toggle wrapper on desktop -->
                        <div v-if="userStore.selectedMedia!=='desktop'" class="toggle-wrapper">
                            <div class="toggle-option" @click="handleDisplayStatus('salesAgents')">
                                <span class="toggle-label">SalesManager</span>
                                <label class="switch">
                                    <input type="radio" name="champions-toggle" value="salesAgents"
                                        :checked="userStore.currentSelection.incentives.display === 'salesAgents'">
                                    <span class="custom-radio"></span>
                                </label>
                            </div>
                            <div class="toggle-option" @click="handleDisplayStatus('salesTeams')">
                                <span class="toggle-label">Areas</span>
                                <label class="switch">
                                    <input type="radio" name="champions-toggle" value="salesTeams"
                                        :checked="userStore.currentSelection.incentives.display==='salesTeams'">
                                    <span class="custom-radio"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <reminder></reminder>
        </div>
    </div>
</template>

<!-- <style src="../scss/components/incentivePreview.scss" lang="scss" scoped></style> -->