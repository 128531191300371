import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFunctionsStore = defineStore('functions', () => {
  const navigationHeight = ref(0)
  const footerHeight = ref(0)
  const gapHeight = 6 + 24 + 24 // 6 is the gap between the navigation and the content, 24 is the padding of the content

  function setNavigationHeight(height) {
    navigationHeight.value = height
  }

  function setFooterHeight(height) {
    footerHeight.value = height + (130 - height) // 130 is the height of the Bubble component. We need to add the difference to the footer height
  }

  return {
    navigationHeight,
    footerHeight,
    gapHeight,
    setNavigationHeight,
    setFooterHeight
  }
})