let browserStorage = {};

function initialize(idUser) {
    const userStorage = localStorage.getItem(`mbaState:${idUser}`);
    if (!userStorage) return false;
    browserStorage = JSON.parse(userStorage);
}

function setKey(idUser, key, value) {
    browserStorage[key] = value;
    localStorage.setItem(`mbaState:${idUser}`, JSON.stringify(browserStorage));
}

export { browserStorage, initialize, setKey };