import { defineStore } from "pinia";

import { useWindowSize } from '@vueuse/core'
const { width } = useWindowSize()

import { initialize as initializeLocalStorage } from "./helpers/localStorage.mjs";
import { fetchFromBackend } from "./helpers/backend.mjs";

import { useIncentivesStore } from "./incentives";
import { usePerformanceStore } from "./performance";
import { useActivityStore } from "./activity";
import { useRatingsStore } from "./ratings";
import { useBusinessPartnersStore } from "./businessPartners";

import { browserStorage, setKey as setBrowserStorageKey } from "./helpers/localStorage.mjs";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      id: null,
      idCampaignLogin: null,
      loginStatus: null,
      maxToDos: {
        followUp: 100000,
        feedback: 100000,
      },
      isLoading: false,
      firstname: "",
      surname: "",
      shortcut: "",
      status: {},
      roleList: [],
      listSegments: [],
      theme: "",
      currentSelection: {
        module: "dashboard",
        scope: null,
        campaign: {
          isLoading: true,
          id: null,
          company: null,
        },
        incentives: {
          isLoading: true,
          display: "salesAgents",
        },
        ratings: {
          isLoading: true,
          display: "week",
        },
        performance: {
          isLoading: false,
          display: "halftime",
        },
        activity: {
          isLoading: true,
          display: "week",
          week: null,
          month: null,
        },
        leadList: {
          isLoading: true,
          signature: '',
          lists: [],
        },
        businessPartnerList: {
          isLoading: true,
          layout: 'grid', // list or grid
          title: '',
          source: 'search', // sources are either 'search' or 'filter'
          signature: '',
          filter: {
            idBusinessPartnerStatus: -1, // previously idSelection 
            idBusinessPartnerAboStatus: -1, // new: the BK status, it wasn't previously part of the filter but did a frontend filter
            idBusinessPartnerList: -1,
            idBusinessPartnerProduct: -1,
            idBusinessPartnerPotential: -1,
            idBusinessPartnerCityZip: -1,
            nameBusinessPartnerSector: '',
            idBusinessPartnerOrder: -1,
            error: false,
            isOpened: false,
          },
          search: {
            query: '',
            mode: 'all',
            error: false,
            isLoading: false,
          },
          page: 1,
          pages: 1,
          pageSize: 24,
          maxRows: 0,
        },
        businessPartner: {
          isLoading: false,
          editMode: false,
          additionalSalesPitches: [],
          hasValid: {
            salesPitch: false,
            contactData: false,
          },
          id: -1,
        },
        salesAgent: {
          isLoading: false,
          id: -1,
        },
        salesTeam: {
          isLoading: false,
          id: -1,
        },
        feedback: {
          idBusinessPartner: -1,
        }
      },
      campaigns: [],
      filterOptions: {
        isLoading: null,
        options: [],
      },

    };
  },
  getters: {
    selectedMedia: (state) => {
      // console.log('current width:', width.value)
      if (width.value < 1024) {
        return 'mobile';
      } else {
        return 'desktop';
      }
    },
    isManagement: (state) => {
      return (state.currentSelection.campaign?.roles?.find((role) => ["MBA:management", "MBA:observer", "MBA:winBack"].includes(role.name)) !== undefined)
    },
    isSalesTeamManager: (state) => {
      return (state.currentSelection.campaign?.roles?.find((role) => role.name === "MBA:salesTeamManager") !== undefined)
    },
    isSalesAgent: (state) => {
      return (state.currentSelection.campaign?.roles?.find((role) => role.name === "MBA:salesAgent") !== undefined)
    },
    selectedCampaign: (state) => {
      const campaign = state.data.find((campaign) => campaign.selected);
      // console.log('selected campaign:', campaign)
      return (campaign) ? campaign : null;
    },
    filterOption: (state) => {
      const filterOptions = (state.currentSelection.salesAgent) ? state.filterOptions.options.find((filterOption) => filterOption.signature === `${state.currentSelection.salesAgent.id}:${state.currentSelection.campaign.id}`) : null;
      return (filterOptions) ? filterOptions.data : null;
    },
    currentFilter: (state) => {
      const filter = {
        idCompany: state.currentSelection?.campaign?.company?.id,
        idCampaign: state.currentSelection?.campaign?.id,
        idSalesTeam: state.currentSelection?.salesTeam?.id,
        idSalesAgent: state.currentSelection?.salesAgent?.id,
      }
      if (state.currentSelection?.businessPartnerList?.filter?.error) state.currentSelection.businessPartnerList.filter.error = null;

      // the filter for the backend needs to be reshabed into regular filter and searchCriteria
      const searchCriteria = []
      const filterSignature = [];
      const filterKeys = [
        'idBusinessPartnerList',
        'idBusinessPartnerStatus',
        'idBusinessPartnerPotential',
        'idBusinessPartnerProduct',
      ];
      const searchCriteriaKeys = [
        'idBusinessPartnerAboStatus',
        'idBusinessPartnerCityZip',
        'nameBusinessPartnerSector',
      ]
      for (const key of Object.keys(state.currentSelection?.businessPartnerList?.filter || [])) {
        if (filterKeys.includes(key)) {
          filter[key] = state.currentSelection.businessPartnerList.filter[key];
          filterSignature.push(`${key}:${filter[key]}`);
        } else if (searchCriteriaKeys.includes(key)) {
          const criterion = {
            field: key,
            value: state.currentSelection.businessPartnerList.filter[key]
          }
          searchCriteria.push(criterion);
          filterSignature.push(`${key}:${criterion.value}`);
        }
      }
      filterSignature.push(`idBusinessPartnerOrder:${state.currentSelection?.businessPartnerList?.filter?.idBusinessPartnerOrder}`);
      filter[`idBusinessPartnerOrder`] = state.currentSelection?.businessPartnerList?.filter?.idBusinessPartnerOrder;

      filter.signature = filterSignature.join('|');
      filter.page = state.currentSelection.businessPartnerList.page;
      filter.pages = state.currentSelection.businessPartnerList.pages;
      filter.pageSize = state.currentSelection.businessPartnerList.pageSize;
      // console.log('user filter', filter, 'with signature', filterSignature)
      return { filter, searchCriteria };
    }
  },
  actions: {
    /** async: fetch the user data from the backend at login
     * @async
    */
    async login(email, password) {
      // console.log(`store login with ${email} and ${password}`)
      this.loginStatus = null;
      const response = await fetchFromBackend('users/v1/login', { email, password })
      if (response.id === -1) {
        this.loginStatus = "error";
        return false;
      }
      this.loginStatus = "success";
      this.maxToDos = {
        feedback: (response.maxToDos?.FeedBack) ? response.maxToDos.FeedBack : 0,
        followUp: (response.maxToDos?.FollowUp) ? response.maxToDos.FollowUp : 0,
      };
      this.id = response.id;
      this.firstname = response.firstname
      this.surname = response.surname
      this.shortcut = response.surname
      this.shortcut = response.shortcut;
      this.roleList = response.roleList;
      this.listSegments = response.listSegments;
      // the updated property keeps track of the last time the campaigns were updated so the only get updated when necessary
      this.updated = new Date();
      // step 1: get the unique campaigns from the roleList
      for (const role of this.roleList) {
        if (this.campaigns.find(campaign => campaign.id === role.idCampaign)) continue;
        const campaign = role.company.campaignList.find(campaign => campaign.id === role.idCampaign);
        this.campaigns.push({
          id: role.idCampaign,
          name: campaign.name,
          position: campaign.position,
          dateStart: campaign.dateStart,
          dateEnd: campaign.dateEnd,
          publicationList: campaign.publicationList,
          company: {
            id: role.company.id,
            name: role.company.name,
          },
          roles: [],
          selected: false
        });
      }
      // step 2: populate the roles the user has within the campaigns as a user can be both salesAgent and salesTeamManager
      for (const campaign of this.campaigns) {
        const roles = this.roleList.filter(role => role.idCampaign === campaign.id);
        for (const role of roles) {
          campaign.roles.push({
            name: role.name,
            team: role.company.team,
            salesAgent: role.salesAgent,
          });
        }
      }

      // see if the browser had the campaign stored in local storage where the user was last logged in
      let campaign = null;
      if (browserStorage?.idCamapign) {
        // there is an idCampaignSelected in local storage => see if it is a valid campaign
        campaign = this.campaigns.find(campaign => campaign.id === parseInt(browserStorage.idCamapign));
      } else {
        // if there is no campaign selected in local storage or the campaign doesn't exist any more, find the campaign where the current date is within the dateStart and dateEnd
        const today = new Date();
        campaign = this.campaigns.find((campaign) => new Date(campaign.dateStart) <= today && new Date(campaign.dateEnd) >= today);
        // if no campaign is active, select the campaign with the highest position
      }
      if (!campaign && this.campaigns && Array.isArray(this.campaigns) && this.campaigns.length > 0) campaign = this.campaigns.reduce((prev, current) => (prev.position > current.position) ? prev : current);
      this.idCampaignLogin = (campaign.id) ? campaign.id : null;
      if (!campaign) return false;

      // if a salesTeamManager or management role is present, set the scope
      if (campaign.roles?.find((role) => ["MBA:management", "MBA:observer", "MBA:winBack"].includes(role.name)) !== undefined) {
        this.currentSelection.scope = 'campaign';
        this.currentSelection.salesTeam.id = -1;
      } else if (campaign.roles?.find((role) => ["MBA:salesTeamManager"].includes(role.name)) !== undefined) {
        this.currentSelection.scope = 'salesTeam';
        this.currentSelection.salesTeam.id = campaign.roles.find((role) => ["MBA:salesTeamManager"].includes(role.name)).team.id;
      } else {
        this.currentSelection.scope = 'salesAgent';
      }
      // console.log('scope after login is', this.currentSelection.scope)


      initializeLocalStorage(response.id);
    },
    /** async: on campaign change, refresh all campaign related data
     * @async
     * @param {number} id the id of the campaign that was selected
    */
    async selectCampaign(id = -1) {
      // console.log('select campaign with id', id);
      const incentivesStore = useIncentivesStore();
      const performanceStore = usePerformanceStore();
      const activityStore = useActivityStore();
      const ratingStore = useRatingsStore();
      const businessPartnerStore = useBusinessPartnersStore();

      const campaignSelected = this.campaigns.find((campaign) => campaign.id === id);
      if (!campaignSelected) return false;

      this.currentSelection.campaign = campaignSelected;
      // select the team based on the campaign and role
      const roleWithSalesTeam = campaignSelected.roles.find((role) => role.team);
      this.currentSelection.salesTeam = (roleWithSalesTeam) ? roleWithSalesTeam.team : { id: -1 };
      // select the login users salesAgent data based on the campaign and role
      const roleWithSalesAgent = campaignSelected.roles.find((role) => role.name === "MBA:salesAgent");
      this.currentSelection.salesAgent = (roleWithSalesAgent) ? roleWithSalesAgent.salesAgent : { id: -1 };
      // console.log('campaign selected:', campaignSelected, 'salesTeam:', roleWithSalesTeam, 'salesAgent:', roleWithSalesAgent)
      // set the selected status on all campaigns to false and the selected campaign to true
      this.currentSelection.campaign.isLoading = true;
      this.campaigns.forEach((campaign) => campaign.selected = false);
      campaignSelected.selected = true;

      // test: load the incentive store first since the performance single page depends on it
      await incentivesStore.refresh();

      // refresh all campaign related stores in parallel to save some time
      let taskList = []
      taskList.push(this.refreshFilterOptions());
      if (this.isSalesAgent) taskList.push(performanceStore.refresh());
      taskList.push(activityStore.refresh());
      taskList.push(ratingStore.refresh());
      await Promise.all(taskList);

      // the tasks to provide specific businessPartnerLists are done bevore seperately from the general refresh to allow lazy loading
      await businessPartnerStore.refresh();

      await this.refreshReminder()

      // ratings will be loaded last as they are not as important as the other data
      // await ratingStore.refresh();

      this.currentSelection.campaign.isLoading = false;

      setBrowserStorageKey("idCampaignSelected", id);
    },
    /** async: if a salesTeamManager or management selects a single salesAgent, refresh all salesAgent related data
     * @async
     * @param {number} id the id of the salesAgent that was selected
    */
    async selectSalesAgent(id = -1) {

      // check if the salesAgent exists in the current campaign
      const salesAgentSelected = this.filterOption.salesAgents.find((salesAgent) => salesAgent.id == id);
      if (!salesAgentSelected) return false;
      this.currentSelection.salesAgent = salesAgentSelected;
      this.currentSelection.scope = 'salesAgent';

      // the filter needs to be refreshed if the salesAgent changes as some options are salesAgent specific like lists
      await this.refreshFilterOptions();
      // console.log('select salesAgent with id', id, 'looking for in', this.filterOption.salesAgents, 'and found', salesAgentSelected, 'team is', this.currentSelection.salesTeam)

      // clear the selected salesTeam to only get the salesAgent specific data
      this.currentSelection.salesTeam = { id: -1 };

      await this.refreshStores()

      setBrowserStorageKey("idSalesAgentSelected", id);
      setBrowserStorageKey("idSalesTeamSelected", -1);
    },
    /** async: if a management role selects a salesTeam (Area), refresh all related data
     * @async
     * @param {number} id the id of the salesTeam that was selected
    */
    async selectSalesTeam(id = -1) {

      // check if the salesTeam exists in the current campaign
      const salesTeamSelected = this.filterOption.salesTeams.find((salesTeam) => salesTeam.id == id);
      if (!salesTeamSelected) return false;
      // console.log('select salesTeam with id', id, 'looking for in', this.filterOption.salesTeams, 'and found', salesTeamSelected)
      this.currentSelection.salesTeam = salesTeamSelected;
      this.currentSelection.scope = 'salesTeam';

      // clear the selected salesAgent to only get the salesTeam specific data
      this.currentSelection.salesAgent = { id: -1 };

      await this.refreshStores()

      setBrowserStorageKey("idSalesTeamSelected", id);
      setBrowserStorageKey("idSalesAgentSelected", -1);
    },
    /** async: if a management role selects the whole company refresh all related data
     * @async
    */
    async selectCompany() {

      // console.log('select the whole company')
      this.currentSelection.salesAgent = { id: -1 };
      this.currentSelection.salesTeam = { id: -1 };
      this.currentSelection.scope = 'company';

      await this.refreshStores()

      setBrowserStorageKey("idSalesTeamSelected", -1);
      setBrowserStorageKey("idSalesAgentSelected", -1);
    },
    /** async: whenever salesAgent, salesTeam or company is selected, refresh all related data
     * @async
    */
    async refreshStores() {
      const performanceStore = usePerformanceStore();
      const activityStore = useActivityStore();
      const ratingStore = useRatingsStore();
      const businessPartnerStore = useBusinessPartnersStore();

      // refresh all salesAgent related stores in parallel to save some time
      let taskList = []
      taskList.push(performanceStore.refresh());
      taskList.push(activityStore.refresh());
      taskList.push(ratingStore.refresh());
      await Promise.all(taskList);
      // the tasks to provide specific businessPartnerLists are done seperate from the general refresh to allow lazy loading
      await businessPartnerStore.refresh();
    },
    /** async: is used to swich the displayed data for various modules i.e. allows to switch from 'halftime' to 'campaign' on the performance module
     * @async
     * @param {string} module the desired module that should display the data
     * @param {string} displayStatus the desired display status
    */
    async selectDisplayStatus(module, displayStatus) {
      this.currentSelection[module].display = displayStatus;
    },
    /** see if the filterOptions need to be fetched again, either because the data is not up to date or the salesAgent has changed
    */
    async refreshFilterOptions() {
      // console.log('filterOptions', this.filterOptions, 'isLoading', this.filterOptions.isLoading, 'currentSelection', this.currentSelection)
      let filterOptionsExists = null;
      // return the existing data if it exists and its update property is less then 6 hours old
      if (this.currentSelection.salesAgent && this.currentSelection.campaign.id) {
        filterOptionsExists = this.filterOptions.options.find((p) => p.signature === `${this.currentSelection.salesAgent.id}:${this.currentSelection.campaign.id}`);
        if (filterOptionsExists && filterOptionsExists?.update > (Date.now() - (6 * 60 * 60 * 1000))) return filterOptionsExists;
      }
      // fetch the data from the backend
      const endpoint = "businessPartners/v1/getFilterOptions";
      this.filterOptions.isLoading = true;
      const response = await fetchFromBackend(endpoint, {
        id: this.id,
        filter: {
          idCompany: this.currentSelection.campaign.company.id,
          idCampaign: this.currentSelection.campaign.id,
          idSalesAgents: (this.isSalesAgent) ? -1 : this.currentSelection.salesAgent.id,
        }
      });
      this.filterOptions.isLoading = false;
      if (!response.filterOptions) return false;

      // to streamline some options, expand the response
      let data = Object.assign({ businessPartnerCityZips: [], businessPartnerLists: [] }, response.filterOptions);
      // create the cityZip options
      data.businessPartnerCityZips = [];
      for (const city of response.filterOptions.businessPartnerCityZip) {
        if (city.zipList?.length === 0) {
          data.businessPartnerCityZips.push({ id: -1, name: city.name });
          continue;
        } else if (city.zipList?.length > 0) {
          data.businessPartnerCityZips.push({
            id: city.zipList.map((zip) => zip.id).join(','),
            name: city.name,
          })
          for (const zipCode of city.zipList) {
            data.businessPartnerCityZips.push({
              id: zipCode.id,
              name: `${zipCode.name}`,
              isZipCode: true
            });
          }
        }
      }
      // create the lists (Kundenlisten i.e. Abo > 500€) options
      data.businessPartnerLists = [];
      for (const segment of response.filterOptions.businessPartnerSegment) {
        if (segment.lists.length === 0) {
          data.businessPartnerLists.push({
            id: segment.id,
            name: segment.name
          });
        } else {
          data.businessPartnerLists.push({
            id: segment.lists.map((list) => `list:${list.id}`).join(','),
            name: segment.name
          });
          for (const list of segment.lists) {
            data.businessPartnerLists.push({
              id: `list:${list.id}`,
              name: `${list.name}`,
              isList: true
            });
          }
        }
      }
      // create the options for products and potential
      for (const section of ['businessPartnerProduct', 'businessPartnerPotential']) {
        data[section] = [];
        for (const item of response.filterOptions[section]) {
          data[section].push({
            id: (item.id === -1) ? -1 : `product:${item.id}`,
            name: item.name,
            useInBusinessPartnerFilter: (section === 'businessPartnerProduct') ? true : item.useInBusinessPartnerFilter,
          });
        }
      }

      // if the data exists update it, if not create it
      if (filterOptionsExists) {
        filterOptionsExists.data = data;
        filterOptionsExists.update = Date.now();
      } else {
        if (!this.currentSelection.salesAgent?.id) {
          const selectedSalesAgent = response.filterOptions.salesAgents.find((salesAgent) => salesAgent.idUser === this.id);
          if (selectedSalesAgent) {
            selectedSalesAgent.selected = true;
            this.currentSelection.salesAgent = selectedSalesAgent
          }
        }
        const selectedSalesTeam = response.filterOptions.salesTeams.find((salesTeam) => salesTeam.id === this.currentSelection.salesTeam?.id);
        if (selectedSalesTeam) {
          selectedSalesTeam.selected = true;
          this.currentSelection.salesTeam = selectedSalesTeam
        }
        this.filterOptions.options.push({
          signature: `${this.currentSelection.salesAgent.id}:${this.currentSelection.campaign.id}`,
          data: data,
          update: Date.now()
        });
      }
      this.resetBusinessPartnerListFilter();
      this.resetLeadFilter();
      // console.log('current filter options are', this.filterOptions.options)
      // console.log('current filter is', this.currentSelection.businessPartnerList.filter)
    },
    /** pull the reminder data from the backend periodically
    */
    async refreshReminder() {
      // console.log('--- > refreshing reminder')
      if ((this.isManagement || this.isSalesTeamManager) && this.currentSelection.salesAgent.id === -1) return false;
      const businessPartnerStore = useBusinessPartnersStore();
      const taskList = [
        businessPartnerStore.getByFilter(businessPartnerStore.namedFilter('Reminder:Wiedervorlagen')),
        businessPartnerStore.getByFilter(businessPartnerStore.namedFilter('Reminder:VorOrtTermine')),
      ];
      await Promise.all(taskList);
    },
    /** sets all options of the businessPartnerList filter to their default values
     * @param {boolean} isOpen if the filter should be open or closed
     */
    resetBusinessPartnerListFilter(isOpen = false) {
      if (!this.currentSelection.salesAgent || !this.currentSelection.campaign.id) return false
      const filterOption = this.filterOptions.options.find((p) => p.signature === `${this.currentSelection.salesAgent.id}:${this.currentSelection.campaign.id}`);
      if (!filterOption) return false;
      this.currentSelection.businessPartnerList.filter = {
        signature: '',
        summary: '',
        idBusinessPartnerStatus: -1, //filterOption.data.businessPartnerStatus[0].id,
        idBusinessPartnerAboStatus: -1, //filterOption.data.businessPartnerAboStatus[0].id,
        idBusinessPartnerList: -1, //filterOption.data.businessPartnerLists[0].id,
        idBusinessPartnerProduct: -1, //filterOption.data.businessPartnerProduct[0].id,
        idBusinessPartnerPotential: -1, //filterOption.data.businessPartnerPotential[0].id,
        idBusinessPartnerCityZip: -1, //filterOption.data.businessPartnerCityZips[0].id,
        nameBusinessPartnerSector: '', //filterOption.data.businessPartnerSector[0].name,
        idBusinessPartnerOrder: -1, //filterOption.data.businessPartnerOrder[0].id,
        error: false,
        isOpen: isOpen, // some pages like the businessPartnerList page may reset the filter but needs to keep it open
      };
      this.currentSelection.businessPartnerList.page = 1;
      this.currentSelection.businessPartnerList.pages = 1;
      this.currentSelection.businessPartnerList.pageSize = 24;
      this.currentSelection.businessPartnerList.maxRows = 0;
      this.currentSelection.businessPartnerList.title = '';
      // console.log('filter reset to:', this.currentSelection.businessPartnerList.filter)
    },
    /** creates a pulldown for the dashboard where for each list in the segment "Leads" or "Sugar Aktionen" there is a number of unseen businessPartners
     */
    resetLeadFilter() {
      if (!this.currentSelection.salesAgent || !this.currentSelection.campaign.id) return false
      const filterOption = this.filterOptions.options.find((p) => p.signature === `${this.currentSelection.salesAgent.id}:${this.currentSelection.campaign.id}`);
      if (!filterOption) return false;
      // TODO: build the leadList from the filterOptions
      this.currentSelection.leadList.lists = [];
      // console.log('leadLists reset to:', this.currentSelection.leadList.lists)
    }
  },
});
