import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router/routes.js'
import { createPinia } from 'pinia'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'

const pinia = createPinia()

createApp(App).use(router).use(AOS.init({once: false})).use(pinia).use(Vueform, vueformConfig).mount('#app')
